import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as ArrowRight } from "../assets/arrow-right.svg";
import ContentWrapper from "./utils/ContentWrapper";
import { useEffect, useState } from "react";

export default function MainNavBar() {
  const [width, setWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
      setOpen(false);
    });
  }, []);

  return (
    <nav className="nav">
      <ContentWrapper>
        <div className="nav__wrapper">
          <div className="nav__logo">
            <Logo />
          </div>

          {width > 1024 && (
            <ul className="nav__links">
              <li>
                <a href="#portfolio_tracker">Portfolio Tracker</a>
              </li>
              <li>
                <a href="#community">Community</a>
              </li>
              <li>
                <a href="#faq">FAQ</a>
              </li>
            </ul>
          )}

          {width > 1024 && (
            <div className="nav__buttons">
              <a href="/login">Login</a>
              <a href="/signup">
                <span>Get Started</span> <ArrowRight />
              </a>
            </div>
          )}

          {width <= 1024 && (
            <div
              className={open ? "menu open" : "menu"}
              onClick={() => setOpen((o) => !o)}
            >
              <div className="menu-burger"></div>
            </div>
          )}

          {width <= 1024 && open && (
            <div className="nav_vertical">
              <ul className="nav_vertical__links">
                <li>
                  <a onClick={() => setOpen(false)} href="#portfolio_tracker">
                    Portfolio Tracker
                  </a>
                </li>
                <li>
                  <a onClick={() => setOpen(false)} href="#community">
                    Community
                  </a>
                </li>
                <li>
                  <a onClick={() => setOpen(false)} href="#faq">
                    FAQ
                  </a>
                </li>
              </ul>

              <div className="nav__buttons">
                <a href="#">Login</a>
                <a href="#">
                  <span>Get Started</span> <ArrowRight />
                </a>
              </div>
            </div>
          )}
        </div>
      </ContentWrapper>
    </nav>
  );
}
