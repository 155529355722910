import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/auth-context";
import { useHttpClient } from "../../hooks/http-hook";

const SearchAssetContainer = (props) => {
  const auth = useContext(AuthContext);
  const [assets, setAssets] = useState([]);
  const { isLoading, error, sendRequest, clearError, setIsLoading } =
    useHttpClient(true);

  useEffect(() => {
    const fetchAssetsData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api/assets/search`,
          "POST",
          JSON.stringify({
            query: props.query,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        setAssets(responseData.results);
      } catch (err) {}
    };

    fetchAssetsData();
  }, [props.query]);

  return (
    <div
      className={
        auth.theme === "dark"
          ? "search_asset__container search_asset__container--dark"
          : "search_asset__container"
      }
    >
      {!isLoading &&
        assets.map((a) => (
          <div
            className="search_asset__item"
            onClick={() => props.clickHandler(a.symbol)}
          >
            <h3>{a.symbol}</h3>
            <p>{a.shortname}</p>
          </div>
        ))}
    </div>
  );
};

export default SearchAssetContainer;
