import { useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";
import Community from "../../../assets/community.svg";
import PieChart from "../../../assets/pie-chart.svg";
import Star from "../../../assets/star.svg";
import CommunityLight from "../../../assets/community-light.svg";
import PieChartLight from "../../../assets/pie-chart-light.svg";
import StarLight from "../../../assets/star-light.svg";
import UserOutline from "../../../assets/user-outline-dark.svg";
import "./NavVerticalMenu.scss";

const NavVerticalMenu = () => {
  const auth = useContext(AuthContext);

  return (
    <div
      className={
        auth.theme === "theme"
          ? "nav_vertical"
          : "nav_vertical nav_vertical--dark"
      }
    >
      <div className="nav_vertical__links">
        <NavLink to="/community">
          <img
            src={auth.theme === "dark" ? CommunityLight : Community}
            alt="Community"
          />
          <span>Community</span>
        </NavLink>
        <NavLink to="/portfolio">
          <img
            src={auth.theme === "dark" ? PieChartLight : PieChart}
            alt="Portfolio"
          />
          <span>Portfolio</span>
        </NavLink>
        <NavLink to="/watchlist">
          <img src={auth.theme === "dark" ? StarLight : Star} alt="Watchlist" />
          <span>Watchlist</span>
        </NavLink>
      </div>

      <Link className="nav_vertical__profile" to="/settings">
        <img src={auth.profileImg ? auth.profileImg : UserOutline} alt="User" />
      </Link>
    </div>
  );
};

export default NavVerticalMenu;
