import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/auth-context";
import ChevronDown from "../../../assets/chevron-down.svg";
import ChevronDownLight from "../../../assets/chevron-down-light.svg";
import "./Select.scss";

export default function Select({ data, value, changeValue }) {
  const auth = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(
    data.find((d) => d.value === value).name
  );

  useEffect(() => {
    changeValue(data.find((d) => d.name === active).value);
  }, [active]);

  return (
    <div className={auth.theme === "dark" ? "select select--dark" : "select"}>
      <div
        onClick={() => setOpen((a) => !a)}
        className={
          open ? "select__header select__header--active" : "select__header"
        }
      >
        <span>{active}</span>
        <img
          src={auth.theme === "dark" ? ChevronDownLight : ChevronDown}
          alt=""
        />
      </div>
      {open && (
        <div className="select__body">
          {data.map((d) => (
            <div
              key={d.name}
              onClick={() => {
                setActive(d.name);
                setOpen(false);
              }}
            >
              {d.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
