import { useContext } from "react";
import { AuthContext } from "../../context/auth-context";
import "./DashboardContainer.scss";

export default function DashboardContainer({ children, className }) {
  const auth = useContext(AuthContext);

  return (
    <div
      className={
        auth.theme === "dark"
          ? "dashboard_container dashboard_container--dark " + className
          : "dashboard_container " + className
      }
    >
      {children}
    </div>
  );
}
