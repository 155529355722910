import { useContext } from "react";
import roundNumber from "../../shared/utils/round-number";
import { AuthContext } from "../../shared/context/auth-context";
import DashboardContainer from "../../shared/components/UIElements/DashboardContainer";

function findClosestPrices(data) {
  return data.map((item) => {
    const now = new Date();
    const currentPrice = item.price;

    const timeDeltas = [
      4 * 3600 * 1000, // 4 hours in milliseconds
      24 * 3600 * 1000, // 1 day in milliseconds
      7 * 24 * 3600 * 1000, // 1 week in milliseconds
      30 * 24 * 3600 * 1000, // 1 month in milliseconds (approximated)
    ];

    const results = {
      img: item.img,
      name: item.name,
      ticker: item.ticker,
      price: item.price,
      quantity: item.quantity,
      total: item.total,
      fourHour: null,
      oneDay: null,
      oneWeek: null,
      oneMonth: null,
    };

    timeDeltas.forEach((delta, index) => {
      const targetDate = new Date(now.getTime() - delta);
      let closestQuote = item.quotes.reduce((prev, curr) => {
        const prevDate = new Date(prev.date),
          currDate = new Date(curr.date);
        const prevDiff = Math.abs(prevDate - targetDate),
          currDiff = Math.abs(currDate - targetDate);
        return prevDiff < currDiff ? prev : curr;
      });

      const property = ["fourHour", "oneDay", "oneWeek", "oneMonth"][index];
      const percentageChange =
        ((currentPrice - closestQuote.close) / closestQuote.close) * 100;
      results[property] = percentageChange;
    });

    return results;
  });
}

const WalletHoldings = (props) => {
  const auth = useContext(AuthContext);
  let data = [];
  if(props.assets)  {
    for (const [key, value] of Object.entries(props.assets)) {
      data.push({
        price: roundNumber(value),
        name: key,
      });
    }
  }
  
  // const data = props.assets.map((a) => {
  //   return {
  //     name: a.asset_data.shortName,
  //     ticker: a.transaction_data.asset,
  //     price: roundNumber(a.asset_data.regularMarketPrice),
  //     quantity: a.transaction_data.quantity,
  //     total: roundNumber(
  //       a.asset_data.regularMarketPrice * a.transaction_data.quantity
  //     ),
  //     quotes: a.chart_data.quotes,
  //   };
  // });

  // const results = findClosestPrices(data);

  return (
    <DashboardContainer className="wallet_holding">
      <h2 className="big">Holdings</h2>
      <hr />
      <div className="portfolio__holdings_header">
        <span>Assets</span>
        {/* <span>Price</span> */}
        {/* <span>4h</span> */}
        {/* <span>1d</span> */}
        {/* <span>1w</span> */}
        {/* <span>1m</span> */}
        {/* <span>Qnt</span> */}
        <span>Value</span>
      </div>

      <div className="portfolio__holdings">
        {data && data.map((a) => (
          <div
            key={a.name}
            className={
              auth.theme === "dark"
                ? "portfolio__holding portfolio__holding--dark"
                : "portfolio__holding"
            }
          >
            <div className="portfolio__holding_name">
              {a.img && <img src={a.img} alt={a.name} />}
              <div>
                <p>{a.name}</p>
                {/* <p>{a.ticker}</p> */}
              </div>
            </div>
            <div className="portfolio__holding_changes">
              {/* <span>{a.price}</span>
              <span className={a.fourHour > 0 ? "positive" : "negative"}>
                {a.fourHour.toFixed(2)}%
              </span>
              <span className={a.oneDay > 0 ? "positive" : "negative"}>
                {a.oneDay.toFixed(2)}%
              </span>
              <span className={a.oneWeek > 0 ? "positive" : "negative"}>
                {a.oneWeek.toFixed(2)}%
              </span>
              <span className={a.oneMonth > 0 ? "positive" : "negative"}>
                {a.oneMonth.toFixed(2)}%
              </span>
              <span>{a.quantity}</span> */}
              <span>{a.price}</span>
            </div>
          </div>
        ))}
      </div>
    </DashboardContainer>
  );
};

export default WalletHoldings;
