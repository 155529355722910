import { useContext, useState } from "react";
import { AuthContext } from "../../context/auth-context";
import ModalContainer from "../UIElements/ModalContainer";
import SearchIcon from "../../../assets/search.svg";
import SearchIconLight from "../../../assets/search-light.svg";
import AssetsSearchContainer from "../Nav/AssetsSearchContainer";

import "./Search.scss";

export default function Search() {
  const auth = useContext(AuthContext);
  const [open, setOpen] = useState();
  const [search, setSearch] = useState();

  const inputHandler = (e) => {
    if (e.target.value) {
      setOpen(true);
      setSearch(e.target.value);
    } else {
      setOpen(false);
      setSearch("");
    }
  };

  const clickHandler = (e) => {
    setOpen(false);
    setSearch("");
  };

  return (
    <div className={auth.theme === "dark" ? "search search--dark" : "search"}>
      <img
        src={auth.theme === "dark" ? SearchIconLight : SearchIcon}
        alt="Search"
      />
      <input
        value={search}
        type="text"
        placeholder="Search"
        onInput={inputHandler}
      />

      {open && <div className="search_results__backdrop"></div>}
      {open && (
        <AssetsSearchContainer query={search} clearSearch={clickHandler} />
      )}
    </div>
  );
}
