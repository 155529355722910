import { useEffect, useContext } from "react";
import Input from "../../shared/components/FormElements/Input";
import Password from "../../shared/components/FormElements/Password";
import { useForm } from "../../shared/hooks/form-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { VALIDATOR_MIN, VALIDATOR_MAX } from "../../shared/utils/validators";
import { useNavigate } from "react-router";
import "./AuthForm.scss";
import Button from "../../shared/components/FormElements/Button";
import Loader from "../../shared/components/Loader";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import NavBar from "../../shared/components/Nav/NavBar";
import ContentWrapper from "../../shared/components/UIElements/ContentWrapper";
import { useLocation } from "react-router";

const VerifyCodeForm = (props) => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [formState, inputHandler, setFormData] = useForm(
    {
      code: {
        value: "",
        isValid: true,
      },
    },
    false
  );

  const resendCodeHandler = async (e) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/users/resendVerificationCode`,
        "POST",
        JSON.stringify({
          email: location.state.email,
          type: 'validate-email',
        }),
        {
          "Content-Type": "application/json",
        }
      );

      console.log(responseData);
    } catch (error) {}
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    if (!formState.isValid) return;
      try {
        const responseData = await sendRequest(
            `${process.env.REACT_APP_API_URL}/api/users/checkCode`,
          "POST",
          JSON.stringify({
            email: location.state.email,
            code: formState.inputs.code.value,
          }),
          {
            "Content-Type": "application/json",
          }
        );

        auth.login(responseData.userId, responseData.admin, responseData.token);
        if(responseData){
            navigate('/assets/AAPL');
        }
      } catch (error) {}
  };

  return (
    <main className="auth">
      <NavBar type="logo-only" />
      <ContentWrapper>
        <div className="auth__wrapper">
          <span className="title">Verify Code</span>
          <form className="auth__form" onSubmit={submitHandler}>
            {isLoading && <Loader />}
            {error && <ErrorModal error={error} clearError={clearError} />}
            {!isLoading && (
              <Input
                id="code"
                type="number"
                validators={[VALIDATOR_MIN(6)]}
                placeholder="123456"
                onInput={inputHandler}
                errorText="Please enter a valid code"
                label="Code"
              />
            )}
            <div className="resend_code_group">
              <span>Didn't receive code?</span>
              <span onClick={resendCodeHandler} className="resend">
                Resend Code
              </span>
            </div>
            {!isLoading && <Button text="Verify" />}
          </form>
        </div>
      </ContentWrapper>
    </main>
  );
};

export default VerifyCodeForm;
