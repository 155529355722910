const firebaseConfig = {
  type: "service_account",
  project_id: "trakfolio-74714",
  private_key_id: "038e0d2b6f5bed22f867eceb24aac7551ff12f7a",
  private_key:
    "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCMPTSOtdXmiGdm\nmiLJmEyIwyjq7PzIGtEp4pAmixEy+TmOiMBY6VcDsKIT6SLST/pXL03FrAC8Di7n\n0WFW++nOdZHh9PKahCJ9y1QnhP/SMNCTxIxExZzrUwyJ7qsYsZEXGGaRo9Ms0oVD\nWtVzmlDLWUEvskpYhgT4uGDTUiNa903yToRDSSHAF3X18albSqoPzs2/6gRxjSLK\nCFZZEpcFWeHQE0/DqtjjbLKypzKiQDCRM0dzJ/dmLWBEFayBH1kZKTl6QwlTzOOe\nvR1hnAYA7MR/01a6jT4PUlvUBPBhXa3zY/OIxDX4EgSFEcuCb2QePoTUu1RhVSEL\nonhPBPJjAgMBAAECggEAK24pAKk/fQ1xOE0zyMrpqNEHsvmBr1bev+HyUn1u7iJQ\nuNuQ9fgNgo8JIrFon0k1dhqoMzd5YsPhstkdFBsf5VhzvU5mc4PISrzVawe9UhLc\nBWcxKXsphZdKoszPH+6dyFE+MoxRsefgbWOMBykbLF6P2X2VmKSolX5EPi2J1Xmb\nvV7bBcA3noFnRlvq2u8ySdSdDPWK1Wmml/xgIsEtC0jmu3B/aggzhIdRD8wVwf1z\nSxTz1XnUlTRjDMUytixDyTNLa7THq4sjR3Y7YNbF3xKQCr7g8Rslxhf4hvo21Aby\nGwB2JIT7ZafBzmmiwaLBTeRwR81F2nYLMs28t0NogQKBgQDCJZXREPnG5H1b7iyW\nfp2+beJM8SDkBljsplcLHp7ujd0RNTZO+nhllz5OL651LSygqf2r/GQSadNtITTH\nGJksO/DBeYeUykeRJ11dFBUOwGv8++cpmChzjeGkApdt9xgajy5bF4MIkOMkvmDW\nZ1+MWgRAuwcA2lXUtOwzIM8rqwKBgQC46vXyANMsDaODlOXaXmpbbI7gPtwzWhgb\n4hDZ0f824/zw1x0+nBjAByAYIvHO8D4t0LRCO1xWnmUlrQQ4T5z0lxojdKvffnyO\n4GYSja05xs3iFnPZYc/3zAnpYGC403HL3+Nxbfx2BcS6KTvKR7Go8Ayt5d6I6QeA\nS3/jPPDcKQKBgFwcIuEmsap4OzWDYWohNkvjrEse16D3lfbo0EE5TOQXMDv6yAJe\neQKR6ACGRLnZLeQn4m9Xdk76IFIVQAn5lKpU4duZAgdIlP279GK1pWvzRQioprqo\nMwDn3pbGl/uXELuBFNgo5oUI4ItNO3pdXwqipoPjd+YisDgTgkCbn06zAoGAeABy\nryP95p7LOD7Ok1GmL8JZTgk9GU4kSyP/z3tJ5jM6GIlg/xqJrURyfXdf+O1LTKwd\n7sVa/RM5jKOlE24G62uLmOrEBvuslYPmea/bdJ0hStFLSQ0Cy0psPDlBS3BcbvPS\nQ/AeX/EKY80ScgjosDtn3wpHyg1/wrA8RBluLVECgYAkLLOzJ/MHw8ugrXcwqYJC\nE+5gTsRe2e3OwQFpijrpJrg/DJQ8HKT4NDHM51DD7mPSH6YrovZoafwIZWzBtVk6\nF7nzLUt7Wx/H2ERuRh2hTmoZ0yMLSYXosDXu0xA2asHJBBsuYhNw/egS6jwM4Zm6\nETRAsfbfwXDFV+zMGLrt9Q==\n-----END PRIVATE KEY-----\n",
  client_email:
    "firebase-adminsdk-p8cb8@trakfolio-74714.iam.gserviceaccount.com",
  client_id: "114259285708946630488",
  auth_uri: "https://accounts.google.com/o/oauth2/auth",
  token_uri: "https://oauth2.googleapis.com/token",
  auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
  client_x509_cert_url:
    "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-p8cb8%40trakfolio-74714.iam.gserviceaccount.com",
  universe_domain: "googleapis.com",
  storageBucket: "gs://trakfolio-74714.appspot.com",
};

export default firebaseConfig;
