import { useContext } from "react";
import { AuthContext } from "../../shared/context/auth-context";
import timeSince from "../../shared/utils/time-since";
import "./Article.scss";

const Article = (props) => {
  const auth = useContext(AuthContext);

  return (
    <a
      target="_blank"
      className={auth.theme === "dark" ? "article article--dark" : "article"}
      href={props.link}
      rel="noreferrer"
    >
      {props.img && <img src={props.img} alt={props.title} />}
      <div className="article__text">
        <p>{timeSince(props.date)}</p>
        <h3>{props.title}</h3>
        <p>{props.publisher}</p>
      </div>
    </a>
  );
};

export default Article;
