import { NavLink } from "react-router-dom";

import Community from "../../../assets/community.svg";
import PieChart from "../../../assets/pie-chart.svg";
import Star from "../../../assets/star.svg";
import CommunityLight from "../../../assets/community-light.svg";
import PieChartLight from "../../../assets/pie-chart-light.svg";
import StarLight from "../../../assets/star-light.svg";
import { AuthContext } from "../../context/auth-context";
import { useContext } from "react";

import "./NavLinks.scss";

export default function NavLinks() {
  const auth = useContext(AuthContext);

  return (
    <div
      className={
        auth.theme === "dark" ? "nav__links nav__links--dark" : "nav__links"
      }
    >
      <NavLink to="/community">
        <img
          src={auth.theme === "dark" ? CommunityLight : Community}
          alt="Community"
        />
        <span>Community</span>
      </NavLink>
      <NavLink to="/portfolio">
        <img
          src={auth.theme === "dark" ? PieChartLight : PieChart}
          alt="Portfolio"
        />
        <span>Portfolio</span>
      </NavLink>
      <NavLink to="/watchlist">
        <img src={auth.theme === "dark" ? StarLight : Star} alt="Watchlist" />
        <span>Watchlist</span>
      </NavLink>
    </div>
  );
}
