import { useState } from "react";

export default function FAQItem({ header, text }) {
  const [open, setOpen] = useState(false);

  return (
    <div className="faq_item">
      <div className="faq_item__header">
        <p>{header}</p>
        <div
          className={open ? "faq_item__plus open" : "faq_item__plus"}
          onClick={() => setOpen((o) => !o)}
        >
          <div></div>
          <div></div>
        </div>
      </div>
      {open && <div className="faq_item__body">{text}</div>}
    </div>
  );
}
