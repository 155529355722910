export default function formatMarketCap(num) {
  // Define thresholds
  const trillion = 1e12;
  const billion = 1e9;
  const million = 1e6;

  // Format with abbreviation
  if (!num) {
    return num;
  }
  else if (num >= trillion) {
    return (num / trillion).toFixed(2) + "T";
  } else if (num >= billion) {
    return (num / billion).toFixed(2) + "B";
  } else if (num >= million) {
    return (num / million).toFixed(2) + "M";
  } else {
    return num.toString();
  }
}
