import { Link } from "react-router-dom";
import ExternalLink from "../../assets/link.svg";
import { useContext } from "react";
import { AuthContext } from "../../shared/context/auth-context";

const DocumentSettings = () => {
  const auth = useContext(AuthContext);

  return (
    <div
      className={
        auth.theme === "dark"
          ? "settings__col_2 settings__col_2--dark"
          : "settings__col_2"
      }
    >
      <h2>Documents</h2>

      <Link
        className={
          auth.theme === "dark"
            ? "settings__link settings__link--dark"
            : "settings__link"
        }
      >
        Terms and Conditions
        <img src={ExternalLink} alt="Terms and Conditions" />
      </Link>
      <Link
        className={
          auth.theme === "dark"
            ? "settings__link settings__link--dark"
            : "settings__link"
        }
      >
        Privacy Policy
        <img src={ExternalLink} alt="Privacy Policy" />
      </Link>
    </div>
  );
};

export default DocumentSettings;
