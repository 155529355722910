import { useState, useEffect } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import NavBar from "../../shared/components/Nav/NavBar";
import ContentWrapper from "../../shared/components/UIElements/ContentWrapper";
import DashboardContainer from "../../shared/components/UIElements/DashboardContainer";
import { useContext } from "react";
import { AuthContext } from "../../shared/context/auth-context";
import Loader from "../../shared/components/Loader";
import Chart from "../components/Chart";
import Allocation from "../components/Allocation";
import Holdings from "../components/Holdings";
import Performance from "../components/Performance";
import roundNumber from "../../shared/utils/round-number";

import "./Portfolio.scss";
import WalletChart from "../components/WalletChart";
import WalletAllocation from "../components/WalletAllocation";
import WalletHoldings from "../components/WalletHolding";

export default function Portfolio() {
  const auth = useContext(AuthContext);

  const [portfolios, setPortfolios] = useState(["Aggregated"]);
  const [portfolio, setPortfolio] = useState("Aggregated");
  const [wallets, setWallets] = useState();
  const [wallet, setWallet] = useState("Aggregated");
  const [tab, setTab] = useState("stock");
  const [cryptoChartPeriod, setCryptoChartPeriod] = useState("day");
  const [walletAssetsData, setWalletAssetsData] = useState();
  const [assetsData, setAssetsData] = useState();
  const [chartData, setChartData] = useState();
  const [startDate, setStartDate] = useState(
    new Date().getTime() - 24 * 60 * 60 * 1000
  );
  const [endDate, setEndDate] = useState(new Date().getTime());
  const [period, setPeriod] = useState("30m");
  const { isLoading, error, sendRequest, clearError, setIsLoading } =
    useHttpClient();

  useEffect(() => {
    const fetchPortfolios = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api/users/get-portfolios`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
          }
        );

        setPortfolios(responseData.portfolios);
        setWallets(responseData.wallets);
        if (responseData.wallets[0]) {
          setWallet(responseData.wallets[0]);
        }
      } catch (err) {}
    };

    fetchPortfolios();
  }, []);

  useEffect(() => {
    const fetchPortfolio = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api/users/get-portfolio`,
          "POST",
          JSON.stringify({
            portfolio,
          }),
          {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
          }
        );

        setAssetsData(responseData.data);
      } catch (err) {}
    };
    if (tab === "stock") {
      fetchPortfolio();
    }
  }, [portfolio, tab]);

  useEffect(() => {
    const fetchWalletInfo = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api/users/get-wallet-chart`,
          "POST",
          JSON.stringify({
            wallet,
            chart_period: cryptoChartPeriod,
          }),
          {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
          }
        );

        setWalletAssetsData(responseData.data);
      } catch (err) {}
    };
    if (tab === "crypto") {
      fetchWalletInfo();
    }
  }, [tab, wallet, cryptoChartPeriod]);
  useEffect(() => {
    const fetchPortfolioChart = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api/users/get-perportfolio-chart`,
          "POST",
          JSON.stringify({
            portfolio,
            period1: new Date(startDate),
            period2: new Date(endDate),
            interval: period,
          }),
          {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
          }
        );

        setChartData(responseData.data);
      } catch (err) {}
    };
    if (tab != "Aggregated") {
      fetchPortfolioChart();
    }
  }, [portfolio, startDate, endDate, period]);

  const setCryptoTimeStampsHandler = (period) => {
    setCryptoChartPeriod(period);
  };

  const setTimeStampsHandler = (start, end, period) => {
    setStartDate(start);
    setEndDate(end);
    setPeriod(period);
  };

  return (
    <section
      className={
        auth.theme === "dark"
          ? "dashboard_section dashboard_section--dark"
          : "dashboard_section"
      }
    >
      <NavBar type="full" transparent={false} />
      <ContentWrapper>
        <main className="dashboard_section__wrapper">
          <div className="portfolio__col_1">
            {portfolio === "Crypto" ? (
              <>
                <DashboardContainer className="portfolio_dashboard">
                  <WalletChart
                    portfolio={portfolio}
                    changePortfolio={setPortfolio}
                    portfolios={portfolios}
                    isLoading={isLoading}
                    data={walletAssetsData}
                    setTime={setCryptoTimeStampsHandler}
                  />
                </DashboardContainer>

                <div className="portfolio__wrapper">
                  {assetsData && <WalletAllocation assets={walletAssetsData} />}
                  {assetsData && <WalletHoldings assets={walletAssetsData} />}
                </div>
              </>
            ) : (
              <>
                <DashboardContainer className="portfolio_dashboard">
                  <Chart
                    portfolio={portfolio}
                    changePortfolio={setPortfolio}
                    setPortfolios={setPortfolios}
                    portfolios={portfolios}
                    isLoading={isLoading}
                    data={chartData}
                    setTime={setTimeStampsHandler}
                  />
                </DashboardContainer>

                <div className="portfolio__wrapper">
                  {assetsData && <Allocation assets={assetsData} />}
                  {assetsData && <Performance assets={assetsData} />}
                </div>

                {assetsData && <Holdings assets={assetsData} />}
              </>
            )}
          </div>
        </main>
      </ContentWrapper>
    </section>
  );
}
