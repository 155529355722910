export const currencies = [
  { value: "AUD", name: "Australian Dollar (AUD)" },
  { value: "BRL", name: "Brazilian Real (BRL)" },
  { value: "GBP", name: "British Pound Sterline (GBP)" },
  { value: "BGN", name: "Bulgarian Lev (BGN)" },
  { value: "CAD", name: "Canadian Dollar (CAD)" },
  { value: "CNY", name: "Chinese Yuan Renminbi (CNY)" },
  { value: "HRK", name: "Croatian Kuna (HRK)" },
  { value: "CZK", name: "Czech Koruna (CZK)" },
  { value: "DKK", name: "Danish Krone (DKK)" },
  { value: "EUR", name: "Euro (EUR)" },
  { value: "HKD", name: "Hong Kong Dollar (HKD)" },
  { value: "HUF", name: "Hungarian Forint (HUF)" },
  { value: "ISK", name: "Icelandic Krona (ISK)" },
  { value: "IDR", name: "Indonesian Rupiah (IDR)" },
  { value: "INR", name: "Indian Rupee (INR)" },
  { value: "ILS", name: "Israeli Shekel (ILS)" },
  { value: "JPY", name: "Japanese Yen (JPY)" },
  { value: "MYR", name: "Malaysian Ringgit (MYR)" },
  { value: "MXN", name: "Mexican Peso (MXN)" },
  { value: "NZD", name: "New Zealand Dollar (NZD)" },
  { value: "NOK", name: "Norwegian Krone (NOK)" },
  { value: "PHP", name: "Philippine Peso (PHP)" },
  { value: "PLN", name: "Polish Zloty (PLN)" },
  { value: "RON", name: "Romanian Leu (RON)" },
  { value: "RUB", name: "Russian Rouble (RUB)" },
  { value: "SGD", name: "Singapore Dollar (SGD)" },
  { value: "ZAR", name: "South African Rand (ZAR)" },
  { value: "KRW", name: "South Korean Won (KRW)" },
  { value: "SEK", name: "Swedish Krona (SEK)" },
  { value: "CHF", name: "Swiss Franc (CHF)" },
  { value: "THB", name: "Thai Baht (THB)" },
  { value: "TRY", name: "Turkish Lira (TRY)" },
  { value: "USD", name: "US Dollar (USD)" },
];
