import { motion } from "framer-motion";

import ContentWrapper from "./utils/ContentWrapper";
import CTAButton from "./utils/CTAButton";
import DashboardPic from "../assets/dashboard-pic.png";

export default function Hero() {
  return (
    <motion.div className="hero">
      <ContentWrapper>
        <motion.div
          className="hero__text"
          animate={{ opacity: 1, scale: 1, translateY: 0 }}
          initial={{ opacity: 0, scale: 0.8, translateY: -100 }}
          transition={{ duration: 0.6 }}
        >
          <header>
            <p>Your Universal Portfolio at a Glance</p>
            <h1>All Your Assets, One Dashboard</h1>
          </header>
          <CTAButton light={true} />
        </motion.div>

        <motion.img
          className="hero__img"
          src={DashboardPic}
          alt="Trakfolio Dashboard"
          animate={{ opacity: 1, scale: 1, translateY: 0 }}
          initial={{ opacity: 0, scale: 0.8, translateY: 100 }}
          transition={{ duration: 0.6 }}
        />
      </ContentWrapper>
    </motion.div>
  );
}
