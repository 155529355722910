import { useContext, useState } from "react";

import NavBar from "../../shared/components/Nav/NavBar";
import ContentWrapper from "../../shared/components/UIElements/ContentWrapper";
import Support from "../../assets/support.svg";
import AppSettings from "../components/AppSettings";
import AccountSettings from "../components/AccountSettings";
import DocumentSettings from "../components/DocumentSettings";
import SettingsNav from "../components/SettingsNav";
import { AuthContext } from "../../shared/context/auth-context";

import "./Settings.scss";

export default function Settings() {
  const auth = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState("app");
  const changeActiveTabHandler = (tab) => {
    setActiveTab(tab);
  };

  return (
    <section
      className={
        auth.theme === "dark"
          ? "dashboard_section dashboard_section--dark"
          : "dashboard_section"
      }
    >
      <NavBar type="full" transparent={false} />

      <ContentWrapper>
        <main className="dashboard_section__wrapper">
          <div
            className={
              auth.theme === "dark"
                ? "settings__col_1 settings__col_1--dark"
                : "settings__col_1"
            }
          >
            <h1>Settings</h1>

            <SettingsNav
              activeTab={activeTab}
              changeActiveTab={changeActiveTabHandler}
            />

            <button className="support_button">
              <img src={Support} alt="Support" />
              Support Center
            </button>
          </div>

          {activeTab === "app" && <AppSettings />}
          {activeTab === "account" && <AccountSettings />}
          {activeTab === "documents" && <DocumentSettings />}
        </main>
      </ContentWrapper>
    </section>
  );
}
