import { useState, useContext } from "react";
import Plus from "../../../assets/plus.svg";
import PlusLight from "../../../assets/plus-white.svg";
import { AuthContext } from "../../context/auth-context";
import AddTransaction from "./AddTransaction";

const AddTransactionButton = ({ ticker }) => {
  const auth = useContext(AuthContext);
  const [openAddTransaction, setOpenAddTransaction] = useState(false);

  return (
    <>
      <button 
        className={
          auth.theme === "dark"
            ? "add_transaction_button add_transaction_button--dark"
            : "add_transaction_button"
        }
        onClick={() => setOpenAddTransaction(true)}>
        <img
          src={auth.theme === "dark" ? PlusLight : Plus}
          alt="Add to watchlist"
        />
        Add To Portfolio
      </button>

      {openAddTransaction && (
        <AddTransaction
          ticker={ticker}
          close={() => setOpenAddTransaction(false)}
        />
      )}
    </>
  );
};

export default AddTransactionButton;
