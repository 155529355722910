import { useContext, useState } from "react";
import UserIcon from "../../assets/user-outline.svg";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import timeSince from "../../shared/utils/time-since";
import { ReactComponent as LikeOutlined } from "../../assets/like.svg";
import { ReactComponent as LikeFill } from "../../assets/like-fill.svg";
import "./Comment.scss";

const Comment = (props) => {
  const auth = useContext(AuthContext);
  const [liked, setLiked] = useState(
    props.likes.find((l) => l === auth.userId)
  );
  const [likesCount, setLikesCount] = useState(
    props.likes ? props.likes.length : 0
  );

  const setLike = () => {
    if (!liked) {
      setLikesCount((l) => ++l);
    } else {
      setLikesCount((l) => --l);
    }

    props.like(props.id, !liked);
    setLiked((l) => !l);
  };

  return (
    <div
      className={auth.theme === "dark" ? "comment comment--dark" : "comment"}
    >
      <img
        className="comment__profile_pic"
        src={props.creator.img ? props.creator.img : UserIcon}
        alt=""
      />
      <div className="comment__body">
        <div className="comment__header">
          <div className="comment__name_wrapper">
            <span className="comment__name">{props.creator.name}</span>
            <span className="comment__tag">{timeSince(props.date)}</span>
          </div>

          {auth.userId === props.creator.id && (
            <button className="comment__button" onClick={props.deleteHandler}>
              Delete
            </button>
          )}
        </div>

        <p className="comment__par">{props.text}</p>

        <div className="comment__footer">
          <div className="comment__footer_item" onClick={setLike}>
            {liked ? <LikeFill /> : <LikeOutlined />}
            {likesCount > 0 ? likesCount : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comment;
