import { useContext, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import Img from "../../assets/image.svg";
import ImgLight from "../../assets/image-light.svg";
import UserOutline from "../../assets/user-outline-dark.svg";
import Clear from "../../assets/clear.svg";
import { firebaseUpload } from "../../shared/hooks/upload-hook";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import "./NewPost.scss";
import Loader from "../../shared/components/Loader";

const NewPost = (props) => {
  const ref = useRef();
  const auth = useContext(AuthContext);
  const [text, setText] = useState("");
  const [postImg, setPostImg] = useState();
  const { isLoading, error, sendRequest, clearError, setIsLoading, setError } =
    useHttpClient();

  const inputHandler = (e) => {
    setText(e.target.value);
  };

  const clearPicture = () => {
    setPostImg(null);
    ref.current.value = "";
  };

  const fileChangeHandler = () => {
    if (ref.current.files[0]) {
      if (ref.current.files[0].size > 2000000) {
        ref.current.value = "";
        setError("Image size must not exceed 2MB!");
        return;
      }

      const fr = new FileReader();

      fr.onload = function () {
        setPostImg(fr.result);
      };

      fr.readAsDataURL(ref.current.files[0]);
    } else {
      setPostImg(null);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const file = ref.current.files[0];

    if (file) {
      const path =
        "Pictures/Posts/" + uuidv4() + "." + file.name.split(".").slice(-1);
      const [getUploadTask, getDownloadURL] = firebaseUpload(path, file);
      const uploadTask = getUploadTask();

      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {},
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            savePost(downloadURL, path);
          });
        }
      );
    } else {
      savePost(null, null);
    }
  };

  const savePost = async (imgURL, imgPath) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/posts/`,
        "POST",
        JSON.stringify({
          text: text,
          creator: auth.userId,
          imgURL,
          imgPath,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      setText("");
      clearPicture();
      props.setPosts(true);
    } catch (error) {}
  };

  return (
    <div
      className={auth.theme === "dark" ? "new_post new_post--dark" : "new_post"}
    >
      {error && <ErrorModal error={error} clearError={clearError} />}
      <img src={auth.profileImg ? auth.profileImg : UserOutline} alt="" />

      <form onSubmit={submitHandler} className="new_post__body">
        <textarea
          placeholder="Share your thoughts..."
          value={text}
          onInput={inputHandler}
        ></textarea>

        {postImg && (
          <div className="new_post__img_wrapper">
            <img className="new_post__img" src={postImg} alt="" />
            <img
              src={Clear}
              className="new_post__clear"
              alt=""
              onClick={clearPicture}
            />
          </div>
        )}

        <div className="new_post__footer">
          <label htmlFor="file_input">
            {!postImg && (
              <img
                src={auth.theme === "dark" ? ImgLight : Img}
                alt="Upload A Picture"
              />
            )}
          </label>

          <input
            id="file_input"
            type="file"
            ref={ref}
            accept="image/*"
            onChange={fileChangeHandler}
          />
          <button className="community__button">Post</button>
        </div>

        {isLoading && <Loader />}
      </form>
    </div>
  );
};

export default NewPost;
