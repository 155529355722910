import ContentWrapper from "./utils/ContentWrapper";
import CTAButton from "./utils/CTAButton";

export default function CTABanner() {
    return <section className="section section--light_blue">
        <ContentWrapper>
            <div className="cta_banner">
                <h2>Join The Investment<br /> World Today!</h2>
                <CTAButton light={true} />
            </div>
        </ContentWrapper>
    </section>
}