import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../shared/context/auth-context";
import NavBar from "../../shared/components/Nav/NavBar";
import ContentWrapper from "../../shared/components/UIElements/ContentWrapper";
import { useHttpClient } from "../../shared/hooks/http-hook";
import TrendingSymbols from "../components/TrendingSymbols";
import CommunityPosts from "../components/CommunityPosts";
import Subscriptions from "../components/Subscriptions";
import "./Forum.scss";

export default function Forum() {
  const [max, setMax] = useState(1);
  const [step, setStep] = useState(0);
  const [posts, setPosts] = useState([]);
  const [activeTab, setActiveTab] = useState("Recent");

  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError, setIsLoading } =
    useHttpClient();

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;

    if (
      scrollTop + clientHeight >= scrollHeight - 50 &&
      !isLoading &&
      max > step
    ) {
      setIsLoading(true);
      fetchPosts(false);
    }
  };

  const fetchPosts = async (reset) => {
    if (reset) {
      setStep(0);
      setPosts([]);
    }

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/posts/get-tab`,
        "POST",
        JSON.stringify({
          tab: activeTab,
          step: reset ? 0 : step,
        }),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        }
      );

      setStep(responseData.nextPage);
      setMax(responseData.max);

      if (responseData) setPosts((p) => [...p, ...responseData.posts]);
    } catch (err) {}
  };

  return (
    <section
      className={
        auth.theme === "dark"
          ? "dashboard_section dashboard_section--dark"
          : "dashboard_section"
      }
      onScroll={handleScroll}
    >
      <NavBar type="full" transparent={false} />

      <ContentWrapper>
        <main className="dashboard_section__wrapper community">
          <TrendingSymbols />
          <CommunityPosts
            fetchPosts={fetchPosts}
            posts={posts}
            setPosts={setPosts}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
          />
          <Subscriptions />
        </main>
      </ContentWrapper>
    </section>
  );
}
