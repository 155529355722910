import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import { useHttpClient } from "../../shared/hooks/http-hook";
import Fire from "../../assets/fire.svg";
import FireLight from "../../assets/fire-light.svg";
import DashboardContainer from "../../shared/components/UIElements/DashboardContainer";
import { AuthContext } from "../../shared/context/auth-context";
import Loader from "../../shared/components/Loader";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import HorizonalAssetItem from "../../shared/components/HorizontalAssetItem";

const TrendingSymbols = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [symbols, setSymbols] = useState();
  const { isLoading, error, sendRequest, clearError, setIsLoading } =
    useHttpClient();

  useEffect(() => {
    const fetchAssetsData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api/assets/trending`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
          }
        );

        setSymbols(responseData.data);
      } catch (err) {}
    };

    fetchAssetsData();
  }, []);

  const clickHandler = (ticker) => {
    navigate("/assets/" + ticker);
  };

  return (
    <div className="community__col_1">
      <DashboardContainer>
        {error && <ErrorModal error={error} clearError={clearError} />}
        <h2>
          <img src={auth.theme === "dark" ? FireLight : Fire} alt="" />
          Trending
        </h2>
        <hr />
        {isLoading && <Loader />}
        {!isLoading &&
          symbols &&
          symbols.map((s) => (
            <HorizonalAssetItem
              onClick={() => clickHandler(s.symbol)}
              key={s.symbol}
              logo={s.coinImageUrl}
              name={s.shortName}
              ticker={s.symbol}
              prevPrice={s.regularMarketPrice - s.regularMarketChange}
              price={s.regularMarketPrice}
            />
          ))}
      </DashboardContainer>
    </div>
  );
};

export default TrendingSymbols;
