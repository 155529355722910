import { useRef, useEffect } from "react";
import { motion, useInView, useAnimationControls } from "framer-motion";

import ContentWrapper from "../../utils/ContentWrapper";
import BenchmarkPic from "../../../assets/benchmark-your-portfolio.png";

export default function BenchmarkSection() {
  const controls = useAnimationControls();
  const controlsProgress = useAnimationControls();

  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: true,
    margin: "0px 0px -300px 0px",
  });

  useEffect(() => {
    controls.set((i) => ({
      opacity: 0.5,
    }));

    controlsProgress.set((i) => ({
      width: 0,
    }));

    controls.start((i) => ({
      opacity: 1,
      transitionEnd: { opacity: 0.5 },
      transition: { delay: i * 4, duration: 4 },
    }));

    controlsProgress.start((i) => ({
      width: "100%",
      transitionEnd: { width: "0%" },
      transition: { delay: i * 4, duration: 4 },
    }));

    setInterval(() => {
      controls.start((i) => ({
        opacity: 1,
        transitionEnd: { opacity: 0.5 },
        transition: { delay: i * 4, duration: 4 },
      }));

      controlsProgress.start((i) => ({
        width: "100%",
        transitionEnd: { width: "0%" },
        transition: { delay: i * 4, duration: 4 },
      }));
    }, 12000);
  }, []);

  return (
    <section className="section section--light_blue" id="benchmark">
      <ContentWrapper>
        <h2 className="section__header">Benchmark Your Portfolio</h2>

        <div className="section__wrapper">
          <div className="section__img">
            <motion.img
              style={{
                opacity: isInView ? 1 : 0,
                translateY: isInView ? 0 : 500,
                transition: "1s ease",
              }}
              src={BenchmarkPic}
              alt="Benchmark Your Portfolio"
            />
          </div>

          <div ref={ref} className="section__benchmark">
            <motion.div
              className="benchmark_tile"
              custom={0}
              animate={controls}
            >
              <h3>Analyze Performance</h3>
              <p>
                Get detailed portfolio breakdowns based on geography, industry,
                asset
              </p>
              <motion.div
                custom={0}
                animate={controlsProgress}
                className="benchmark_tile__progress"
              ></motion.div>
            </motion.div>

            <motion.div
              className="benchmark_tile"
              custom={1}
              animate={controls}
            >
              <h3>Keep Transaction Record</h3>
              <p>Keep a track of all financial transactions</p>
              <motion.div
                custom={1}
                animate={controlsProgress}
                className="benchmark_tile__progress"
              ></motion.div>
            </motion.div>

            <motion.div
              className="benchmark_tile"
              custom={2}
              animate={controls}
            >
              <h3>Add Custom Assets</h3>
              <p>
                Create dedicated portfolios to track custom assets such as cash
                or NFTs
              </p>
              <motion.div
                custom={2}
                animate={controlsProgress}
                className="benchmark_tile__progress"
              ></motion.div>
            </motion.div>
          </div>
        </div>
      </ContentWrapper>
    </section>
  );
}
