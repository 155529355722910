import { useState, useEffect, useContext } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import NewPost from "./NewPost";
import Posts from "./Posts";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import { AuthContext } from "../../shared/context/auth-context";

const CommunityPosts = (props) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError, setIsLoading } =
    useHttpClient();

  const changeTab = (tab) => {
    props.setActiveTab(tab);
  };

  const bookmark = async (postId, bookmark) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/posts/bookmark`,
        "POST",
        JSON.stringify({
          bookmark,
          postId,
        }),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        },
        false
      );

      props.setPosts((posts) => {
        posts.find((p) => p.id === postId).bookmarks = responseData.bookmarks;
        return posts;
      });
    } catch (err) {}
  };

  const like = async (postId, like) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/posts/like`,
        "POST",
        JSON.stringify({
          like,
          postId,
        }),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        },
        false
      );

      props.setPosts((posts) => {
        posts.find((p) => p.id === postId).likes = responseData.likes;
        return posts;
      });
    } catch (err) {}
  };

  useEffect(() => {
    props.fetchPosts(true);
  }, [props.activeTab]);

  return (
    <div className="community__col_2">
      {error && <ErrorModal error={error} clearError={clearError} />}
      <NewPost setPosts={props.fetchPosts} />
      <Posts
        posts={props.posts}
        bookmark={bookmark}
        like={like}
        changeTab={changeTab}
        activeTab={props.activeTab}
        isLoading={isLoading}
      />
    </div>
  );
};

export default CommunityPosts;
