import { useReducer, useEffect, useContext } from "react";
import { validate } from "../../utils/validators";
import { AuthContext } from "../../context/auth-context";
import "./Input.scss";

function inputReducer(state, action) {
  switch (action.type) {
    case "CHANGE":
      return {
        ...state,
        value: action.val,
        isValid: true,
      };
    case "TOUCH":
      return {
        ...state,
        isValid: validate(action.val, action.validators),
        isTouched: true,
      };
    default:
      return state;
  }
}

const Input = (props) => {
  const auth = useContext(AuthContext);
  const [inputState, dispatch] = useReducer(inputReducer, {
    value: props.value || "",
    isTouched: false,
    isValid: true,
  });

  const { id, onInput } = props;
  const { value, isValid } = inputState;

  useEffect(() => {
    onInput(id, value, isValid);
  }, [id, value, isValid, onInput]);

  function changeHandler(e) {
    dispatch({
      type: "CHANGE",
      val: e.target.value,
    });
  }

  function touchHandler(e) {
    dispatch({
      type: "TOUCH",
      val: e.target.value,
      validators: props.validators,
    });
  }

  return (
    <div
      className={
        auth.theme === "dark"
          ? "input_container input_container--dark"
          : "input_container"
      }
    >
      <label htmlFor={props.id}>{props.label}</label>
      <input
        value={inputState.value}
        onChange={changeHandler}
        onBlur={touchHandler}
        id={props.id}
        type={props.type}
        className={!inputState.isValid ? "error" : ""}
        placeholder={props.placeholder}
      />

      {!inputState.isValid && <p>{props.errorText}</p>}
    </div>
  );
};

export default Input;
