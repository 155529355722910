import ContentWrapper from "../utils/ContentWrapper";
import FAQItem from "./FAQItem";

export default function FAQ() {
  const FAQs = [
    {
      header: "How much does Trakfolio cost?",
      text: "Lorem ipsum dolor sit amet consectetur. Semper amet enim at vitae morbi. Quam nulla enim eu convallis blandit. Arcu vitae faucibus pellentesque turpis. Accumsan amet suspendisse nunc dignissim volutpat pharetra nunc. Aliquam diam massa nisl dignissim mauris tincidunt ullamcorper. Pharetra leo tortor amet volutpat enim maecenas cursus sed. Ut odio orci tristique parturient egestas. Blandit et eu ipsum urna sit duis sed suspendisse vitae. Lectus tempus magna eget sit quisque ac quis ut morbi.",
    },
    {
      header: "How do I import my assets into my portfolio?",
      text: "Lorem ipsum dolor sit amet consectetur. Semper amet enim at vitae morbi. Quam nulla enim eu convallis blandit. Arcu vitae faucibus pellentesque turpis. Accumsan amet suspendisse nunc dignissim volutpat pharetra nunc. Aliquam diam massa nisl dignissim mauris tincidunt ullamcorper. Pharetra leo tortor amet volutpat enim maecenas cursus sed. Ut odio orci tristique parturient egestas. Blandit et eu ipsum urna sit duis sed suspendisse vitae. Lectus tempus magna eget sit quisque ac quis ut morbi.",
    },
    {
      header: "What assets can I import to my portfolio tracker?",
      text: "Lorem ipsum dolor sit amet consectetur. Semper amet enim at vitae morbi. Quam nulla enim eu convallis blandit. Arcu vitae faucibus pellentesque turpis. Accumsan amet suspendisse nunc dignissim volutpat pharetra nunc. Aliquam diam massa nisl dignissim mauris tincidunt ullamcorper. Pharetra leo tortor amet volutpat enim maecenas cursus sed. Ut odio orci tristique parturient egestas. Blandit et eu ipsum urna sit duis sed suspendisse vitae. Lectus tempus magna eget sit quisque ac quis ut morbi.",
    },
    {
      header: "What happens to my data?",
      text: "Lorem ipsum dolor sit amet consectetur. Semper amet enim at vitae morbi. Quam nulla enim eu convallis blandit. Arcu vitae faucibus pellentesque turpis. Accumsan amet suspendisse nunc dignissim volutpat pharetra nunc. Aliquam diam massa nisl dignissim mauris tincidunt ullamcorper. Pharetra leo tortor amet volutpat enim maecenas cursus sed. Ut odio orci tristique parturient egestas. Blandit et eu ipsum urna sit duis sed suspendisse vitae. Lectus tempus magna eget sit quisque ac quis ut morbi.",
    },
    {
      header: "How can I keep an overview of my portfolio using Trakfolio?",
      text: "Lorem ipsum dolor sit amet consectetur. Semper amet enim at vitae morbi. Quam nulla enim eu convallis blandit. Arcu vitae faucibus pellentesque turpis. Accumsan amet suspendisse nunc dignissim volutpat pharetra nunc. Aliquam diam massa nisl dignissim mauris tincidunt ullamcorper. Pharetra leo tortor amet volutpat enim maecenas cursus sed. Ut odio orci tristique parturient egestas. Blandit et eu ipsum urna sit duis sed suspendisse vitae. Lectus tempus magna eget sit quisque ac quis ut morbi.",
    },
  ];

  return (
    <section id="faq" className="section section--light_blue">
      <ContentWrapper>
        <h2 className="section__header">FAQ</h2>

        <div className="faq">
          {FAQs.map((f, i) => (
            <FAQItem key={i} {...f} />
          ))}
        </div>
      </ContentWrapper>
    </section>
  );
}
