import Google from "../../assets/google.png";
import "./GoogleLogin.scss";

const GoogleLogin = () => {
  return (
    <div className="auth__alt">
      <div className="auth__alt_item">
        <img src={Google} alt="Google" />
        <span>Continue with Google</span>
      </div>
    </div>
  );
};

export default GoogleLogin;
