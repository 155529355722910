import { useContext, useEffect, useState } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import OutlinedStar from "../../assets/star-outline.svg";
import OutlinedStarLight from "../../assets/star-outline-light.svg";
import Star from "../../assets/star-filled.svg";
import StarLight from "../../assets/star-filled-light.svg";
import AddTransactionButton from "../../shared/components/AddTransaction/AddTransactionButton";
import "./AssetHeader.scss";

export default function AssetHeader({ name, ticker, logo }) {
  const auth = useContext(AuthContext);
  const [isFavorite, setIsFavorite] = useState(false);
  const [openAddTransaction, setOpenAddTransaction] = useState(false);
  const { isLoading, error, sendRequest, clearError, setIsLoading } =
    useHttpClient();

  useEffect(() => {
    const fetchFavorite = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api/users/check-favorite`,
          "POST",
          JSON.stringify({
            ticker,
          }),
          {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
          }
        );
        setIsFavorite(responseData.favorite);
      } catch (err) {}
    };

    fetchFavorite();
  }, []);

  const saveFavorite = async () => {
    setIsFavorite((f) => !f);
    try {
      await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/users/set-favorite`,
        "POST",
        JSON.stringify({
          ticker,
          setFavorite: !isFavorite,
        }),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        }
      );
    } catch (err) {}
  };

  return (
    <div
      className={
        auth.theme === "dark"
          ? "asset_header asset_header--dark"
          : "asset_header"
      }
    >
      {error && <ErrorModal error={error} clearError={clearError} />}

      <div className="asset_header__name">
        {logo && (
          <div className="asset_header__img">
            <img src={logo} alt="Trakfolio" />
          </div>
        )}
        <div>
          <p>{name}</p>
          <p>{ticker}</p>
        </div>
      </div>

      <div className="asset_header__save">
        <AddTransactionButton ticker={ticker} />

        {!isFavorite && (
          <img
            onClick={saveFavorite}
            src={auth.theme === "dark" ? OutlinedStarLight : OutlinedStar}
            alt="Add to watchlist"
          />
        )}

        {isFavorite && (
          <img
            onClick={saveFavorite}
            src={auth.theme === "dark" ? StarLight : Star}
            alt="Add to watchlist"
          />
        )}
      </div>
    </div>
  );
}
