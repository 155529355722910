import { useContext } from "react";
import { AuthContext } from "../context/auth-context";
import "./Loader.scss";

const Loader = () => {
  const auth = useContext(AuthContext);

  return (
    <div className="loader__wrapper">
      <div
        className={auth.theme === "dark" ? "loader loader--light" : "loader"}
      ></div>
    </div>
  );
};

export default Loader;
