import { useState, useContext } from "react";
import Plus from "../../../assets/plus.svg";
import PlusLight from "../../../assets/plus-white.svg";
import { AuthContext } from "../../context/auth-context";
import AddPortfolio from "./AddPortfolio";

const AddPortfolioButton = (props) => {
  const auth = useContext(AuthContext);
  const [openAddPortfolio, setOpenAddPortfolio] = useState(false);

  const closeHandler = (name) => {
    setOpenAddPortfolio(false);
    console.log(name);
    if (name) props.setPortfolios((p) => [...p, name]);
  };

  return (
    <>
      <button
        className="add_portfolio_button"
        onClick={() => setOpenAddPortfolio(true)}
      >
        <img
          src={auth.theme === "dark" ? PlusLight : Plus}
          alt="Add to watchlist"
        />
        Add To Portfolio
      </button>

      {openAddPortfolio && <AddPortfolio close={closeHandler} />}
    </>
  );
};

export default AddPortfolioButton;
