import { useEffect, useRef } from "react";
import { motion, useInView } from "framer-motion";

import ContentWrapper from "../utils/ContentWrapper";
import CommunityPic from "../../assets/investors-community.png";
import CommunityTopics from "../../assets/community-topics.png";

export default function CommunitySection() {
  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: true,
    margin: "0px 0px -300px 0px",
  });

  return (
    <section id="community" className="section section--light_blue">
      <ContentWrapper>
        <h2 className="section__header">Join A Vibrant Investors Community</h2>
        <motion.div
          ref={ref}
          style={{
            opacity: isInView ? 1 : 0,
            translateY: isInView ? 0 : 100,
            transition: "1s ease",
          }}
          className="community_grid"
        >
          <div className="community_grid__item">
            <div className="community_grid__header">
              <h3>1,000+</h3>
              <p>Active Members</p>
            </div>
            <img src={CommunityPic} alt="Community" />
          </div>

          <div className="community_grid__item">
            <div className="community_grid__header">
              <h3 className="community_grid__h3">Keep An Eye on Hot Topics</h3>
            </div>
            <img
              className="community_grid__graph"
              src={CommunityTopics}
              alt="Community Topics"
            />
          </div>
        </motion.div>
      </ContentWrapper>
    </section>
  );
}
