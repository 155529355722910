import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Auth from "./users/pages/Auth";
import AccountSetup from "./users/pages/AccountSetup";
import Settings from "./users/pages/Settings";
import Forum from "./forum/pages/Forum";
import WatchList from "./fin-trackers/pages/WatchList";
import Asset from "./fin-trackers/pages/Asset";
import Portfolio from "./fin-trackers/pages/Portfolio";
import useAuth from "./shared/hooks/auth-hook";
import { AuthContext } from "./shared/context/auth-context";
import { useHttpClient } from "./shared/hooks/http-hook";
import Creator from "./forum/pages/Creator";
import Main from "./main/pages/main";
import VerifyCodeForm from "./users/components/VerifyCodeForm";

export default function App() {
  const [profilePic, setProfilePic] = useState();
  const [theme, setTheme] = useState();
  const [currency, setCurrency] = useState();
  const [updateProfile, setUpdateProfile] = useState(false);
  const { token, login, logout, userId, admin } = useAuth();
  const { isLoading, error, sendRequest, clearError, setIsLoading } =
    useHttpClient();

  const navigate = <Navigate to="/" />;
  const assets = <Navigate to="/assets/AAPL" />;
  useEffect(() => {
    const getUserData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api/users/` + userId,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        );

        setProfilePic(responseData.user.img);
        setTheme(responseData.user.theme);
        setCurrency(responseData.user.currency);
      } catch (error) {
        logout();
      }
    };

    if (!!token) {
      getUserData();
    }
  }, [token, userId, sendRequest, updateProfile]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token,
        login,
        logout,
        userId,
        theme,
        currency,
        tiingoKey: "11134d05d7c304f3b5af3332873f7b35aa31108d",
        profileImg: profilePic,
        updateProfile: () => {
          setUpdateProfile((u) => !u);
        },
        updateTheme: (theme) => {
          setTheme(theme);
        },
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={ <Main />} />
          <Route path="/login" element={!token ? <Auth /> : assets} />
          <Route path="/signup" element={!token ? <Auth /> : assets} />
          <Route path="/verify-code" element={<VerifyCodeForm />} />
          <Route path="/setup" element={<AccountSetup /> } />
          <Route path="/settings" element={token ? <Settings /> : navigate} />
          <Route path="/community" element={token ? <Forum /> : navigate} />
          <Route path="/watchlist" element={token ? <WatchList /> : navigate} />
          <Route path="/assets/:asset" element={token ? <Asset /> : navigate} />
          <Route
            path="/creators/:creator"
            element={token ? <Creator /> : navigate}
          />
          <Route path="/portfolio" element={token ? <Portfolio /> : navigate} />
          <Route
            path="/*"
            element={<Navigate to={!token ? "/" : "/assets/AAPL"} />}
          />
        </Routes>
      </BrowserRouter>
      <ToastContainer 
        position="top-right"
        autoClose={1500}
      />
    </AuthContext.Provider>
  );
}
