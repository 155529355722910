import { useContext } from "react";
import { AuthContext } from "../../context/auth-context";
import "./ErrorModal.scss";

const ErrorModal = (props) => {
  const auth = useContext(AuthContext);

  const closeError = () => {
    props.clearError();
  };

  return (
    <div
      className={
        auth.theme === "dark"
          ? "error_message error_message--dark"
          : "error_message"
      }
    >
      <div className="error_message__backdrop" onClick={closeError}></div>
      <div className="error_message__modal">
        <h2 className="error_message__header">Error Occurred</h2>
        <p className="error_message__par">
          {props.error ? props.error : "Undefined error occured!"}
        </p>
        <button className="error_message__button" onClick={closeError}>
          Close
        </button>
      </div>
    </div>
  );
};

export default ErrorModal;
