import { useContext, useState } from "react";
import Close from "../../../assets/close.svg";
import CloseLight from "../../../assets/close-light.svg";
import { AuthContext } from "../../context/auth-context";
import { useHttpClient } from "../../hooks/http-hook";
import "./AddWalletAddress.scss";

const AddWalletAddress = (props) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError, setIsLoading } =
    useHttpClient();

  const [address, setAddress] = useState();
  const [name, setName] = useState();

  const submitHandler = async () => {
    try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api/users//update-wallet`,
          "POST",
          JSON.stringify({
            name,
            address,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );

      props.close();
    } catch (error) {}
  };

  return (
    <div
      className={
        auth.theme === "dark" ? "add_wallet add_wallet--dark" : "add_wallet"
      }
    >
      <div className="add_wallet__backdrop" onClick={props.close}></div>
      <div className="add_wallet__modal">
        <div className="add_wallet__header">
          <h3>Add Wallet Address</h3>

          <button className="add_wallet__close" onClick={props.close}>
            <img src={auth.theme === "dark" ? CloseLight : Close} alt="Close" />
          </button>
        </div>

        <div className="add_wallet__body">
          <div className="add_wallet__group">
            <label htmlFor="name">Name</label>
            <input
              id="name"
              type="text"
              placeholder="Primary"
              value={name}
              onInput={(e) => setName(e.target.value)}
            />
            <label htmlFor="quantity">Address</label>
            <input
              id="address"
              type="text"
              placeholder="0x42b9df65b219b...."
              value={address}
              onInput={(e) => setAddress(e.target.value)}
            />
          </div>
        </div>

        <button
          disabled={!address || !name}
          onClick={submitHandler}
          className="add_wallet__button"
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default AddWalletAddress;
