import { useContext, useEffect, useRef, useState } from "react";
import Input from "../../shared/components/FormElements/Input";
import { VALIDATOR_REQUIRE } from "../../shared/utils/validators";
import UserOutline from "../../assets/user-outline.svg";
import Button from "../../shared/components/FormElements/Button";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { firebaseDelete, firebaseUpload } from "../../shared/hooks/upload-hook";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import Loader from "../../shared/components/Loader";
import Clear from "../../assets/clear.svg";

const AccountSettings = () => {
  const ref = useRef();
  const auth = useContext(AuthContext);
  const [imgPath, setImgPath] = useState();
  const [firstLoad, setFirstLoad] = useState(true);
  const [profilePicture, setProfilePicture] = useState(auth.profileImg);
  const { isLoading, error, sendRequest, clearError, setIsLoading } =
    useHttpClient();
  const [formState, inputHandler, setFormData] = useForm(
    {
      name: {
        value: "",
        isValid: true,
      },
    },
    false
  );

  const fileImgChangeHandler = () => {
    if (ref.current.files[0]) {
      const fr = new FileReader();

      fr.onload = function () {
        setProfilePicture(fr.result);
      };

      fr.readAsDataURL(ref.current.files[0]);
    } else {
      setProfilePicture(UserOutline);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api/users/` + auth.userId,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
          }
        );

        setImgPath(responseData.user.imgPath);
        setFormData(
          {
            name: {
              value: responseData.user.name,
              isValid: true,
            },
          },
          true
        );
        setFirstLoad(false);
      } catch (err) {}
    };

    fetchUserData();
  }, []);

  const updateSettings = async (imgURL, imgPath) => {
    if (!formState.isValid) return;
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/users/update-user`,
        "POST",
        JSON.stringify({
          name: formState.inputs.name.value,
          imgURL,
          imgPath,
        }),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        }
      );

      console.log(responseData);
      auth.updateProfile(responseData.user.img);
    } catch (err) {}
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (imgPath) {
      firebaseDelete(imgPath);
    }

    if (profilePicture) {
      const path =
        "Pictures/UserProfiles/" +
        auth.userId +
        "." +
        ref.current.files[0].name.split(".").slice(-1);

      const [getUploadTask, getDownloadURL] = firebaseUpload(
        path,
        ref.current.files[0]
      );

      const uploadTask = getUploadTask();
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {},
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setImgPath(path);
            updateSettings(downloadURL, path);
          });
        }
      );
    } else {
      updateSettings("", "");
    }
  };

  return (
    <div
      className={
        auth.theme === "dark"
          ? "settings__col_2 settings__col_2--dark"
          : "settings__col_2"
      }
    >
      {error && <ErrorModal error={error} clearError={clearError} />}
      <h2>Account Settings</h2>

      {!firstLoad && (
        <form className="settings__form" onSubmit={submitHandler}>
          {profilePicture && (
            <img
              src={Clear}
              alt=""
              onClick={(e) => {
                e.stopPropagation();
                setProfilePicture(null);
              }}
              className="settings__clear_picture"
            />
          )}

          <label
            htmlFor="file"
            className={
              auth.theme === "dark"
                ? "settings__img_container settings__img_container--dark"
                : "settings__img_container"
            }
          >
            <img src={profilePicture ? profilePicture : UserOutline} alt="" />
            <div>
              <p>Upload Your Profile Picture</p>
              <p>*Optional</p>
            </div>
          </label>

          <input
            onChange={fileImgChangeHandler}
            ref={ref}
            id="file"
            type="file"
            style={{ display: "none" }}
          />

          <div className="settings__inputs">
            {!isLoading && (
              <Input
                id="name"
                type="text"
                validators={[VALIDATOR_REQUIRE()]}
                placeholder="Your name"
                onInput={inputHandler}
                errorText="Please enter a name"
                label="Name"
                value={formState.inputs.name.value}
              />
            )}

            {/* <Input
            id="username"
            type="text"
            validators={[VALIDATOR_REQUIRE()]}
            placeholder="Username"
            onInput={() => {}}
            errorText="Please enter a username"
            label="Username"
          /> */}
          </div>

          <Button text="Update" />
        </form>
      )}
      {isLoading && <Loader />}
    </div>
  );
};

export default AccountSettings;
