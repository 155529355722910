import { useState } from "react";

import Input from "./Input";
import EyeIcon from "../../../assets/eye.svg";
import EyeOffIcon from "../../../assets/eye-off.svg";
import { VALIDATOR_MINLENGTH } from "../../utils/validators";

import "./Password.scss";

const Password = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordHandler = () => {
    setShowPassword((s) => !s);
  };

  return (
    <div className="password">
      <Input
        id={props.id}
        type={showPassword ? "text" : "password"}
        value={props.value}
        validators={[VALIDATOR_MINLENGTH(8)]}
        placeholder="Password"
        onInput={props.onInput}
        isValid={props.isValid}
        errorText="Password must contain at least 8 characters"
        label="Password"
      />
      <button type="button" onClick={togglePasswordHandler}>
        <img src={showPassword ? EyeOffIcon : EyeIcon} alt="" />
      </button>
    </div>
  );
};

export default Password;
