import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import DashboardContainer from "../../shared/components/UIElements/DashboardContainer";
import UserOutline from "../../assets/user-outline-dark.svg";
import Loader from "../../shared/components/Loader";
import { useNavigate } from "react-router";

const Subscriptions = () => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [subscriptions, setSubscriptions] = useState([]);
  const { isLoading, error, sendRequest, clearError, setIsLoading } =
    useHttpClient();

  const getSubscriptions = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/users/subscriptions`,
        "GET",
        null,
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        }
      );

      setSubscriptions(responseData.subscriptions);
    } catch (err) {}
  };

  useEffect(() => {
    getSubscriptions();
  }, []);

  return (
    <div className="community__col_3">
      <DashboardContainer>
        <h2>Subscriptions</h2>
        <hr />
        {subscriptions &&
          subscriptions.map((s) => (
            <div
              onClick={() => navigate("/creators/" + s.id)}
              key={s.tag}
              className={
                auth.theme === "dark"
                  ? "community__subscription community__subscription--dark"
                  : "community__subscription"
              }
            >
              <img src={s.img ? s.img : UserOutline} alt={s.name} />
              <div>
                <p>{s.name}</p>
              </div>
            </div>
          ))}
        {subscriptions && subscriptions.length === 0 && (
          <p className="community__no_subscriptions">No subscriptions</p>
        )}
        {isLoading && <Loader />}
      </DashboardContainer>
    </div>
  );
};

export default Subscriptions;
