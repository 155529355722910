import { useContext } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import roundNumber from "../../shared/utils/round-number";
import DashboardContainer from "../../shared/components/UIElements/DashboardContainer";
import { AuthContext } from "../../shared/context/auth-context";

const colors = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#A83731",
  "#5F4B8B",
  "#50BFE6",
  "#FF605F",
  "#FFC300",
  "#B0DE09",
];

function processDataAndAssignColors(rawData) {
  let data = [];
  for (const [key, value] of Object.entries(rawData)) {
    data.push({
      name: key,
      total: roundNumber(value),
    });
  }
  const totalSum = data.reduce((acc, curr) => acc + parseFloat(curr.total), 0);

  const processedData = data.map((item, index) => {
    const percentage = (parseFloat(item.total) / totalSum) * 100;
    const colorIndex = index < 10 ? index : 9;
    return {
      ...item,
      percentage: percentage.toFixed(2) + "%",
      color: colors[colorIndex],
    };
  });

  return processedData;
}

function createChartData(data) {
  const maxItems = 10;
  let chartData = [];
  let othersTotal = 0;

  data.forEach((item, index) => {
    if (index < maxItems - 1) {
      // For the first 9 items, add them directly to chartData
      chartData.push({
        value: parseFloat(item.total),
        name: item.name,
      });
    } else {
      // Sum totals of the rest into "Others"
      othersTotal += parseFloat(item.total);
    }
  });

  // If there were more than 9 items, add an "Others" item
  if (data.length > maxItems - 1) {
    chartData.push({
      value: othersTotal,
      name: "Others",
    });
  }

  return chartData;
}

const WalletAllocation = (props) => {
  const auth = useContext(AuthContext);
  const data = props.assets && processDataAndAssignColors(props.assets);
  const chartData = data && createChartData(data);

  return (
    <DashboardContainer className="allocation">
      <h2 className="big">Assets Allocation</h2>

      <div
        className={
          auth.theme === "dark"
            ? "portfolio_chart portfolio_chart--dark"
            : "portfolio_chart"
        }
      >
        <ResponsiveContainer width="100%" height={170}>
          <PieChart width="100%" height="100%">
            <Pie
              data={chartData}
              dataKey="value"
              stroke="none"
              cx="50%"
              cy="50%"
              outerRadius="95%"
              innerRadius="70%"
            >
              {chartData && chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </div>

      <div
        className={
          auth.theme === "dark"
            ? "portfolio__allocation portfolio__allocation--dark"
            : "portfolio__allocation"
        }
      >
        {data && data.map((a) => (
          <div key={a.name} className="portfolio__allocation_item">
            <div className="portfolio__allocation_name">
              {a.img && <img src={a.img} alt={a.name} />}
              <div>
                <p>{a.name}</p>
                <p>{a.ticker}</p>
              </div>
            </div>

            <div className="portfolio__allocation_percentage">
              <div style={{ background: a.color }}></div>
              <span>{a.percentage}</span>
            </div>
          </div>
        ))}
      </div>
    </DashboardContainer>
  );
};

export default WalletAllocation;
