import { useContext, useState, useEffect } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import NavBar from "../../shared/components/Nav/NavBar";
import ContentWrapper from "../../shared/components/UIElements/ContentWrapper";
import DashboardContainer from "../../shared/components/UIElements/DashboardContainer";
import HorizonalAssetItem from "../../shared/components/HorizontalAssetItem";
import AmazonLogo from "../../assets/amazon-logo.svg";
import ProfilePic from "../../assets/profile-pic.png";
import PostsPic from "../../assets/post-pic.png";
import Post from "../../forum/components/Post";
import AssetHeader from "../components/AssetHeader";
import Chart from "../components/Chart";
import Article from "../components/Article";
import formatMarketCap from "../../shared/utils/format-market-cap";
import Loader from "../../shared/components/Loader";
import ChevronDown from "../../assets/chevron-down.svg";
import ChevronDownLight from "../../assets/chevron-down-light.svg";
import { ReactComponent as PlusWhite } from "../../assets/plus-white.svg";
import { AuthContext } from "../../shared/context/auth-context";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import "./Watchlist.scss";
import timeSince from "../../shared/utils/time-since";

export default function WatchList() {
  const auth = useContext(AuthContext);
  const [favorites, setFavorites] = useState([]);
  const [activeTicker, setActiveTicker] = useState();
  const [posts, setPosts] = useState();
  const [asset, setAsset] = useState();
  const [news, setNews] = useState();
  const [chartData, setChartData] = useState();
  const [startDate, setStartDate] = useState(
    new Date().getTime() - 24 * 60 * 60 * 1000
  );
  const [endDate, setEndDate] = useState(new Date().getTime());
  const [period, setPeriod] = useState("30m");

  const { isLoading, error, sendRequest, clearError, setIsLoading } =
    useHttpClient();

  useEffect(() => {
    const fetchFavorite = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api/assets/favorites`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
          }
        );

        setActiveTicker(responseData.favorites[0].symbol);
        setFavorites(responseData.favorites);
      } catch (err) {}
    };

    fetchFavorite();
  }, []);

  useEffect(() => {
    const fetchAssetsData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api/assets/`,
          "POST",
          JSON.stringify({ ticker: activeTicker }),
          {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
          }
        );

        setAsset(responseData.data);
        setNews(responseData.news);
        setPosts(responseData.posts);
      } catch (err) {}
    };

    activeTicker && fetchAssetsData();
  }, [sendRequest, activeTicker]);

  useEffect(() => {
    const fetchChartData = async () => {
      let start = new Date(startDate),
        end = new Date(endDate);

      if (start.getDay() === 6) {
        start.setDate(start.getDate() - 2);
      }

      if (start.getDay() === 0) {
        start.setDate(start.getDate() - 3);
      }

      if (end.getDay() === 6) {
        end.setDate(end.getDate() - 1);
      }

      if (end.getDay() === 0) {
        end.setDate(end.getDate() - 2);
      }

      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api/assets/chart`,
          "POST",
          JSON.stringify({
            ticker: activeTicker,
            period1: start,
            period2: end,
            interval: period,
          }),
          {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
          }
        );

        setChartData(
          responseData.quotes
            .map((q) => {
              return {
                price: q.close,
                time_stamp: q.date,
                volume: q.volume,
              };
            })
            .filter((q) => q.price)
        );
      } catch (err) {}
    };

    activeTicker && fetchChartData();
  }, [sendRequest, auth.tiingoKey, activeTicker, startDate, endDate, period]);

  const setTimeStampsHandler = (start, end, period) => {
    setStartDate(start);
    setEndDate(end);
    setPeriod(period);
  };

  const bookmark = async (postId, bookmark) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/posts/bookmark`,
        "POST",
        JSON.stringify({
          bookmark,
          postId,
        }),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        },
        false
      );

      setPosts((posts) => {
        posts.find((p) => p.id === postId).bookmarks = responseData.bookmarks;
        return posts;
      });
    } catch (err) {}
  };

  const like = async (postId, like) => {
    console.log(postId, like);
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/posts/like`,
        "POST",
        JSON.stringify({
          like,
          postId,
        }),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        },
        false
      );

      console.log(responseData);

      setPosts((posts) => {
        posts.find((p) => p.id === postId).likes = responseData.likes;
        return posts;
      });
    } catch (err) {}
  };

  return (
    <section
      className={
        auth.theme === "dark"
          ? "dashboard_section dashboard_section--dark"
          : "dashboard_section"
      }
    >
      <NavBar type="full" transparent={false} />
      <ContentWrapper>
        {error && <ErrorModal error={error} clearError={clearError} />}
        <main className="dashboard_section__wrapper watchlist">
          <div className="watchlist__col_1">
            <DashboardContainer>
              {asset && (
                <AssetHeader
                  name={asset.shortName}
                  ticker={activeTicker}
                  logo={asset.coinImageUrl}
                />
              )}
              {!asset && <Loader />}
              <hr />
              {asset && (
                <div
                  className={
                    auth.theme === "dark"
                      ? "watchlist__links watchlist__links--dark"
                      : "watchlist__links"
                  }
                >
                  <a href="#chart" className="active">
                    Chart
                  </a>
                  <a href="#financials">Financials</a>
                  <a href="#about">About</a>
                  <a href="#discussion">Discussion</a>
                </div>
              )}
              {chartData && (
                <Chart
                  isLoading={isLoading}
                  data={chartData}
                  setTime={setTimeStampsHandler}
                />
              )}
            </DashboardContainer>

            <DashboardContainer>
              <h2 id="financials" className="big">
                Financials
              </h2>
              <hr />
              {!asset && <Loader />}
              {asset && (
                <div
                  className={
                    auth.theme === "dark"
                      ? "watchlist__financials watchlist__financials--dark"
                      : "watchlist__financials"
                  }
                >
                  <div>
                    <span>Asset Type</span>
                    <span title={asset.typeDisp}>{asset.typeDisp}</span>
                  </div>
                  <div>
                    <span>Market Cap</span>
                    <span>{formatMarketCap(asset.marketCap)}</span>
                  </div>

                  {asset.volume24Hr && (
                    <div>
                      <span>Volume (24h)</span>
                      <span>{formatMarketCap(asset.volume24Hr)}</span>
                    </div>
                  )}

                  {asset.forwardPE && (
                    <div>
                      <span>Forward PE</span>
                      <span>{asset.forwardPE}</span>
                    </div>
                  )}

                  {asset.financialData && (
                    <>
                      <div>
                        <span>Total Cash</span>
                        <span>
                          {formatMarketCap(asset.financialData.totalCash)}
                        </span>
                      </div>
                      <div>
                        <span>Cash/Share</span>
                        <span>{asset.financialData.totalCashPerShare}</span>
                      </div>
                      <div>
                        <span>Ebitda</span>
                        <span>
                          {formatMarketCap(asset.financialData.ebitda)}
                        </span>
                      </div>
                      <div>
                        <span>Total Revenue</span>
                        <span>
                          {formatMarketCap(asset.financialData.totalRevenue)}
                        </span>
                      </div>
                      <div>
                        <span>Free Cash Flow</span>
                        <span>
                          {formatMarketCap(asset.financialData.freeCashflow)}
                        </span>
                      </div>
                    </>
                  )}
                </div>
              )}
            </DashboardContainer>

            <DashboardContainer>
              <h2 className="big">About</h2>
              <hr />
              {!asset && <Loader />}
              {asset && (
                <p className="watchlist__about">
                  {asset.assetProfile.longBusinessSummary
                    ? asset.assetProfile.longBusinessSummary
                    : asset.assetProfile.description}
                </p>
              )}
            </DashboardContainer>

            {news && news.length > 0 && (
              <DashboardContainer>
                <h2 className="big">Related Articles</h2>
                <hr />
                {news.map((n) => (
                  <Article
                    link={n.link}
                    img={
                      n.thumbnail &&
                      n.thumbnail.resolutions &&
                      n.thumbnail.resolutions[0] &&
                      n.thumbnail.resolutions[0].url
                    }
                    title={n.title}
                    publisher={n.publisher}
                    date={n.providerPublishTime}
                  />
                ))}
              </DashboardContainer>
            )}

            {(!posts || posts.length > 0) && (
              <DashboardContainer>
                <h2 className="big">Discussion</h2>
                <hr />
                {posts &&
                  posts.map((p) => (
                    <Post
                      id={p.id}
                      creator={p.creator}
                      name={p.creator.name}
                      time={timeSince(p.date)}
                      postContent={p.text}
                      postPic={p.imgURL}
                      likes={p.likes}
                      comments={p.comments}
                      bookmarks={p.bookmarks}
                      bookmark={bookmark}
                      like={like}
                    />
                  ))}
                {!posts && <Loader />}
              </DashboardContainer>
            )}
          </div>

          <div
            className={
              auth.theme === "dark"
                ? "watchlist__col_2 watchlist__col_2--dark"
                : "watchlist__col_2"
            }
          >
            <DashboardContainer className="watchlist_list">
              <div className="watchlist__header">
                <button className="watchlist__lists">
                  <span>Watchlist</span>
                  {/* <img
                    src={auth.theme === "dark" ? ChevronDownLight : ChevronDown}
                    alt=""
                  /> */}
                </button>
                {/* <button className="watchlist__button">
                  <PlusWhite />
                  <span>Watchlist</span>
                </button> */}
              </div>
              <hr />
              {!favorites && <Loader />}
              {favorites && (
                <div className="watchlist__container">
                  {favorites &&
                    favorites.map((f) => (
                      <HorizonalAssetItem
                        onClick={() => setActiveTicker(f.symbol)}
                        key={f.symbol}
                        logo={f.coinImageUrl}
                        name={f.shortName}
                        ticker={f.symbol}
                        prevPrice={f.regularMarketPrice - f.regularMarketChange}
                        price={f.regularMarketPrice}
                      />
                    ))}
                </div>
              )}
            </DashboardContainer>
          </div>
        </main>
      </ContentWrapper>
    </section>
  );
}
