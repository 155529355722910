import { useContext, useState } from "react";
import { AuthContext } from "../../shared/context/auth-context";
import UserOutline from "../../assets/user-outline-dark.svg";
import { ReactComponent as LikeOutlined } from "../../assets/like.svg";
import { ReactComponent as LikeFill } from "../../assets/like-fill.svg";
import { ReactComponent as Comment } from "../../assets/comment.svg";
import { ReactComponent as BookmarkOutlined } from "../../assets/bookmark-outlined.svg";
import { ReactComponent as BookmarkFill } from "../../assets/bookmark-fill.svg";
import PostComments from "./PostComments";
import "./Post.scss";
import { Link } from "react-router-dom";

export default function Post(props) {
  const auth = useContext(AuthContext);

  const {
    creator,
    name,
    time,
    postContent,
    postPic,
    likes,
    comments,
    bookmark,
    like,
    bookmarks,
    id,
    deletePost,
  } = props;

  const [bookmarked, setBookmarked] = useState(
    bookmarks.find((b) => b === auth.userId)
  );

  const [bookmarksCount, setBookmarksCount] = useState(
    bookmarks ? bookmarks.length : 0
  );

  const setBookmark = () => {
    if (!bookmarked) {
      setBookmarksCount((b) => ++b);
    } else {
      setBookmarksCount((b) => --b);
    }

    bookmark(id, !bookmarked);
    setBookmarked((b) => !b);
  };

  const [liked, setLiked] = useState(likes.find((l) => l === auth.userId));
  const [likesCount, setLikesCount] = useState(likes ? likes.length : 0);

  const setLike = () => {
    if (!liked) {
      setLikesCount((l) => ++l);
    } else {
      setLikesCount((l) => --l);
    }

    like(id, !liked);
    setLiked((l) => !l);
  };

  const [commentsCount, setCommentsCount] = useState(
    comments ? comments.length : 0
  );

  const [openComments, setOpenComments] = useState(false);

  return (
    <>
      <div className={auth.theme === "dark" ? "post post--dark" : "post"}>
        <Link to={"/creators/" + creator._id}>
          <img
            className="post__profile_pic"
            src={creator.img ? creator.img : UserOutline}
            alt={name}
          />
        </Link>

        <div className="post__body">
          <div className="post__header">
            <div className="post__name_wrapper">
              <Link to={"/creators/" + creator._id}>
                <span className="post__name">{name}</span>
              </Link>
              <span className="post__tag">{time}</span>
            </div>

            {deletePost && (
              <button onClick={() => deletePost(id)} className="post__button">
                Delete
              </button>
            )}
          </div>
          <p className="post__par">{postContent}</p>
          {postPic && <img className="post__img" src={postPic} alt="" />}

          <div className="post__footer">
            <div className="post__footer_item" onClick={setLike}>
              {liked ? <LikeFill /> : <LikeOutlined />}
              {likesCount > 0 ? likesCount : ""}
            </div>
            <div
              className="post__footer_item"
              onClick={() => setOpenComments(true)}
            >
              <Comment />
              {commentsCount > 0 ? commentsCount : ""}
            </div>
            <div className="post__footer_item" onClick={setBookmark}>
              {bookmarked ? <BookmarkFill /> : <BookmarkOutlined />}
              {bookmarksCount > 0 ? bookmarksCount : ""}
            </div>
          </div>
        </div>
      </div>

      {openComments && (
        <PostComments
          id={id}
          name={name}
          time={time}
          creator={creator}
          like={setLike}
          bookmark={setBookmark}
          postContent={postContent}
          postPic={postPic}
          liked={liked}
          setLike={setLike}
          bookmarked={bookmarked}
          likesCount={likesCount}
          setBookmark={setBookmark}
          commentsCount={commentsCount}
          setCommentsCount={setCommentsCount}
          bookmarksCount={bookmarksCount}
          closePost={() => setOpenComments(false)}
        />
      )}
    </>
  );
}
