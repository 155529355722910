import { initializeApp } from "firebase/app";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

import firebaseConfig from "../utils/firebase-config";

export function firebaseUpload(url, file) {
  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);
  const storageRef = ref(storage, url);

  const getUploadTask = () => {
    const uploadTask = uploadBytesResumable(storageRef, file, {});
    return uploadTask;
  };

  return [getUploadTask, getDownloadURL];
}

export function firebaseDelete(url) {
  const app = initializeApp(firebaseConfig);
  const storage = getStorage(app);
  const storageRef = ref(storage, url);

  deleteObject(storageRef)
    .then(() => {})
    .catch((error) => {});
}
