import { useEffect, useContext } from "react";
import Input from "../../shared/components/FormElements/Input";
import Password from "../../shared/components/FormElements/Password";
import { useForm } from "../../shared/hooks/form-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_EMAIL,
} from "../../shared/utils/validators";
import { useNavigate } from "react-router";
import "./AuthForm.scss";
import Button from "../../shared/components/FormElements/Button";
import Loader from "../../shared/components/Loader";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";

const AuthForm = (props) => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [formState, inputHandler, setFormData] = useForm(
    {
      email: {
        value: "",
        isValid: true,
      },
      password: {
        value: "",
        isValid: true,
      },
    },
    false
  );

  useEffect(() => {
    if (props.isLogin) {
      setFormData(
        {
          ...formState.inputs,
          name: undefined,
        },
        formState.inputs.email.isValid && formState.inputs.password.isValid
      );
    } else {
      setFormData(
        {
          ...formState.inputs,
          name: {
            value: "",
            isValid: false,
          },
        },
        false
      );
    }
  }, [props.isLogin]);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!formState.isValid) return;
    if (props.isLogin) {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api/users/login`,
          "POST",
          JSON.stringify({
            email: formState.inputs.email.value,
            password: formState.inputs.password.value,
          }),
          {
            "Content-Type": "application/json",
          }
        );

        auth.login(responseData.userId, responseData.admin, responseData.token);
      } catch (error) {}
    } else {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api/users/signup`,
          "POST",
          JSON.stringify({
            name: formState.inputs.name.value,
            email: formState.inputs.email.value,
            password: formState.inputs.password.value,
          }),
          {
            "Content-Type": "application/json",
          }
        );
          // auth.login(responseData.userId, responseData.admin, responseData.token);
          if(responseData){
            navigate('/verify-code', {
              state: {
                email: formState.inputs.email.value,
              }
            })
          }
        
      } catch (error) {}
    }
  };

  return (
    <form className="auth__form" onSubmit={submitHandler}>
      {isLoading && <Loader />}
      {error && <ErrorModal error={error} clearError={clearError} />}
      {!isLoading && (
        <div className="auth__inputs">
          {!props.isLogin && (
            <Input
              id="name"
              type="text"
              validators={[VALIDATOR_REQUIRE()]}
              placeholder="Enter your name"
              onInput={inputHandler}
              errorText="Please enter a name"
              label="Name"
            />
          )}

          <Input
            id="email"
            type="text"
            validators={[VALIDATOR_EMAIL()]}
            placeholder="Email"
            onInput={inputHandler}
            errorText="Please enter a valid email"
            label="Email Address"
          />

          <Password id="password" onInput={inputHandler} />
        </div>
      )}

      {!isLoading && <Button text={props.isLogin ? "Log In" : "Sign Up"} />}
    </form>
  );
};

export default AuthForm;
