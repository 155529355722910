import MainNavBar from "../components/MainNavBar";
import Hero from "../components/Hero";
import TrackYourAssetsSection from "../components/Sections/TrackYourAssetsSection";
import BenchmarkSection from "../components/Sections/BenchmarkSection/BenchmarkSection";
import GainDeepInsightSection from "../components/Sections/GainDeepInsightSection";
import CommunitySection from "../components/Sections/CommunitySecton";
import ConnectPortfolioSection from "../components/Sections/ConnectPortfolioSection";
import CTABanner from "../components/CTABanner";
import FAQ from "../components/FAQ/FAQ";
import Footer from "../components/Footer/Footer";
import "./main.scss";

export default function Main() {
  return (
    <>
      <MainNavBar />
      <main>
        <Hero />
        <TrackYourAssetsSection />
        <BenchmarkSection />
        <GainDeepInsightSection />
        <CommunitySection />
        <ConnectPortfolioSection />
        <CTABanner />
        <FAQ />
      </main>
      <Footer />
    </>
  );
}

