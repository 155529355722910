import { useState, useCallback, useEffect } from "react";

let logoutTimer;
export default function useAuth() {
  const [token, setToken] = useState();
  const [tokenExp, setTokenExp] = useState();
  const [userId, setUserId] = useState();
  const [admin, setAdmin] = useState();

  const login = useCallback((userId, adm, token, expirationDate) => {
    setToken(token);
    setAdmin(adm);
    setUserId(userId);

    const tokenExpDate =
      expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 24);

    setTokenExp(tokenExpDate);
    localStorage.setItem(
      "userData",
      JSON.stringify({
        userId: userId,
        token: token,
        admin: adm,
        expiration: tokenExpDate.toISOString(),
      })
    );
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setUserId(null);
    setTokenExp(null);
    setAdmin(null);
    localStorage.removeItem("userData");
  }, []);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (
      userData &&
      userData.token &&
      new Date(userData.expiration) > new Date()
    ) {
      login(
        userData.userId,
        userData.admin,
        userData.token,
        new Date(userData.expiration)
      );
    }
  }, [login]);

  useEffect(() => {
    if (token && tokenExp) {
      const remainingTime = tokenExp.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExp]);

  return { token, login, logout, userId, admin };
}
