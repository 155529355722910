import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";
import Search from "../FormElements/Search";
import UserOutline from "../../../assets/user-outline-dark.svg";

import "./NavProfile.scss";

const NavProfile = () => {
  const auth = useContext(AuthContext);

  return (
    <div
      className={
        auth.theme === "dark"
          ? "nav__profile nav__profile--dark"
          : "nav__profile"
      }
    >
      <Search />
      <Link to="/settings">
        <img
          src={auth.profileImg ? auth.profileImg : UserOutline}
          alt="User Profile"
        />
      </Link>
    </div>
  );
};

export default NavProfile;
