import { useState, useContext } from "react";
import { Link } from "react-router-dom";

import NavBar from "../../shared/components/Nav/NavBar";
import ContentWrapper from "../../shared/components/UIElements/ContentWrapper";
import Select from "../../shared/components/FormElements/Select";
import Search from "../../shared/components/FormElements/Search";

import Metamask from "../../assets/metamask.png";
import InteractiveBrokers from "../../assets/interactive-brokers.png";
import Robinhood from "../../assets/robinhood.png";
import Coinbase from "../../assets/coinbase.png";
import Fideliy from "../../assets/fidelity.png";
import Binance from "../../assets/binance.png";

import { ReactComponent as ChevronRight } from "../../assets/chevron-right.svg";
import { ReactComponent as Checkmark } from "../../assets/checkmark-white.svg";
import { ReactComponent as UserOutline } from "../../assets/user-outline.svg";

import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";

import { themes } from "../../shared/data/themes";
import { currencies } from "../../shared/data/currencies";

export default function ProfileSetup() {
  const auth = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState("profile");
  const [activeTheme, setActiveTheme] = useState(themes[0].value);
  const [activeCurrency, setActiveCurrency] = useState(currencies[0].value);
  
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const updateProfile = () => {
    setActiveTab("app");
  }

  const updateAppSetting = () => {
    setActiveTab("app");
  }

  const updatePortfolio = () => {
    setActiveTab("app");
  }

  const setAppSetting = async (e) => {
    e.preventDefault();

    try {
      await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/users/update-user`,
        "POST",
        JSON.stringify({
          theme: activeTheme,
          currency: activeCurrency,
        }),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        }
      );

      auth.updateTheme(activeTheme);
    } catch (err) {}
  };

  const portfolios = [
    {
      name: "Metamask",
      img: Metamask,
      onClick: () => {},
    },
    {
      name: "Interactive Brokers",
      img: InteractiveBrokers,
      onClick: () => {},
    },
    {
      name: "Robinhood",
      img: Robinhood,
      onClick: () => {},
    },
    {
      name: "Coinbase",
      img: Coinbase,
      onClick: () => {},
    },
    {
      name: "Fideliy",
      img: Fideliy,
      onClick: () => {},
    },
    {
      name: "Binance",
      img: Binance,
      onClick: () => {},
    },
  ];

  return (
    <main className="setup">
      <NavBar type="logo-only" transparent={true} />
      <ContentWrapper>
        <div
          className={
            activeTab === "portfolio"
              ? "setup__wrapper setup__wrapper--wide"
              : "setup__wrapper"
          }
        >
          <div className="setup__nav">
            <button onClick={() => setActiveTab("profile")} className="active">
              {activeTab === "profile" ? (
                <span>1</span>
              ) : (
                <span>
                  <Checkmark />
                </span>
              )}
              <span>Profile Setup</span>
            </button>

            <button
              onClick={() => activeTab !== "profile" && setActiveTab("app")}
              className={
                (activeTab === "app" || activeTab !== "profile") && "active"
              }
            >
              {activeTab === "app" || activeTab === "profile" ? (
                <span>2</span>
              ) : (
                <span>
                  <Checkmark />
                </span>
              )}
              <span>App Setup</span>
            </button>

            <button className={activeTab === "portfolio" && "active"}>
              <span>3</span>
              <span>Add Your Portfolio</span>
            </button>
          </div>

          {activeTab === "profile" && (
            <div className="setup__container">
              <h1 className="setup__header">Your Profile</h1>
              <div className="setup__img_container">
                <UserOutline />
                <div>
                  <p>Upload Your Profile Picture</p>
                  <p>*Optional</p>
                </div>
              </div>

              <div className="setup__form">
                <div className="setup__inputs">
                  <div className="setup__group">
                    <label htmlFor="username">Username</label>
                    <input
                      id="username"
                      className="input"
                      type="text"
                      placeholder="Enter your username..."                      
                    />
                  </div>

                  <div className="setup__group">
                    <label htmlFor="name">Name</label>
                    <input
                      id="name"
                      className="input"
                      type="text"
                      placeholder="Enter your name..."
                    />
                  </div>
                </div>

                <button
                  onClick={updateProfile}
                  className="setup__button"
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {activeTab === "app" && (
            <div className="setup__container">
              <h1 className="setup__header">App Setup</h1>
              <div className="setup__form">
                <div className="setup__inputs">
                  <div className="setup__group">
                    <label>Theme</label>
                    <Select data={themes} value={themes[0].value} changeValue={(val) => setActiveTheme(val)}/>
                  </div>

                  <div className="setup__group">
                    <label>Currency</label>
                    <Select data={currencies} value={currencies[0].value} changeValue={(val) => setActiveCurrency(val)}/>
                  </div>
                </div>

                <button
                  className="setup__button"
                  onClick={updateAppSetting}
                >
                  Next
                </button>
              </div>
            </div>
          )}

          {activeTab === "portfolio" && (
            <div className="setup__container">
              <h1 className="setup__header">Add Your Portoflio</h1>
              <Search />
              <div className="setup__portfolios">
                {portfolios.map((p) => (
                  <div key={p.name} className="setup__portfolio">
                    <img src={p.img} alt={p.name} />
                    <p>{p.name}</p>
                  </div>
                ))}
              </div>
            </div>
          )}

          {activeTab === "portfolio" && (
            <Link to="/portfolio" className="setup__link">
              <span>Skip Per Now</span>
              <ChevronRight />
            </Link>
          )}
        </div>
      </ContentWrapper>
    </main>
  );
}
