import { useContext, useState } from "react";
import { AuthContext } from "../../shared/context/auth-context";
import Loader from "../../shared/components/Loader";
import roundNumber from "../../shared/utils/round-number";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import "./Chart.scss";
import AddTransactionButton from "../../shared/components/AddTransaction/AddTransactionButton";
import AddPortfolioButton from "../../shared/components/AddPortfolio/AddPortfolioButton";

const periods = [
  {
    name: "1d",
    period: "5m",
    startDate: () => {
      let date = new Date();
      date.setDate(date.getDate() - 1);

      return date.getTime();
    },
    endDate: () => {
      return new Date().getTime();
    },
  },
  {
    name: "1w",
    period: "30m",
    startDate: () => {
      let date = new Date();
      date.setDate(date.getDate() - 7);
      return date.getTime();
    },
    endDate: () => {
      return new Date().getTime();
    },
  },
  {
    name: "1m",
    period: "1h",
    startDate: () => {
      let date = new Date();
      date.setMonth(date.getMonth() - 1);
      return date.getTime();
    },
    endDate: () => {
      return new Date().getTime();
    },
  },
  {
    name: "1y",
    period: "1d",
    startDate: () => {
      let date = new Date();
      date.setFullYear(date.getFullYear() - 1);
      return date.getTime();
    },
    endDate: () => {
      return new Date().getTime();
    },
  },
  {
    name: "5y",
    period: "1wk",
    startDate: () => {
      let date = new Date();
      date.setFullYear(date.getFullYear() - 5);
      return date.getTime();
    },
    endDate: () => {
      return new Date().getTime();
    },
  },
];

function formatDate(date, activePeriod) {
  const options = { hour: "2-digit", minute: "2-digit", hour12: true };
  switch (activePeriod) {
    case "1d":
      return new Intl.DateTimeFormat("en-US", {
        weekday: "short",
        ...options,
        hour12: true,
      }).format(date);
    case "1w":
      return new Intl.DateTimeFormat("en-US", {
        weekday: "short",
        day: "2-digit",
        ...options,
      }).format(date);
    case "1m":
      return new Intl.DateTimeFormat("en-US", {
        weekday: "short",
        day: "2-digit",
      }).format(date);
    case "1y":
      return new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      }).format(date);
    case "5y":
      return new Intl.DateTimeFormat("en-US", {
        month: "short",
        year: "numeric",
      }).format(date);
    default:
      return new Intl.DateTimeFormat("en-US").format(date);
  }
}

export default function Chart(props) {
  const auth = useContext(AuthContext);
  const [activePeriod, setActivePeriod] = useState("1d");

  let data =
    props.data &&
    props.data.map((d) => {
      return {
        price: roundNumber(d.price),
        time_stamp: formatDate(new Date(d.time_stamp), activePeriod),
      };
    });
  if (data && data.length === 0) {
    data = undefined;
  }
  const prices = data && data.map((item) => item.price);
  const minPrice = data ? Math.min(...prices) : 0;
  const maxPrice = data ? Math.max(...prices) : 1000;

  const prevPrice = data ? roundNumber(data[0].price) : 0;
  const currentPrice = data ? roundNumber(data[data.length - 1].price) : 0;
  const diffPrice = data ? roundNumber(currentPrice - prevPrice) : 0;
  const diffPercentage = data ? ((diffPrice / prevPrice) * 100).toFixed(2) : 0;

  const changePeriod = (period) => {
    setActivePeriod(period.name);
    props.setTime(period.startDate(), period.endDate(), period.period);
  };
  return (
    <div>
      {props.portfolio && (
        <div
          className={
            auth.theme === "dark"
              ? "portfolio__header portfolio__header--dark"
              : "portfolio__header"
          }
        >
          <div className="portfolio__name">
            <p>{currentPrice} USD</p>
            <span className={diffPrice >= 0 ? "positive" : "negative"}>
              {diffPrice} USD ({diffPercentage}%)
            </span>
          </div>
            <AddTransactionButton />
        </div>
      )}

      <div className="chart__header">
        {!props.portfolio && (
          <div
            className={
              auth.theme === "dark"
                ? "chart__name chart__name--dark"
                : "chart__name"
            }
          >
            <h2>{currentPrice} USD</h2>
            <span className={diffPrice >= 0 ? "positive" : "negative"}>
              {roundNumber(diffPrice)} USD ({diffPercentage}%)
            </span>
          </div>
        )}

        {props.portfolio && (
          <div
            className={
              auth.theme === "dark"
                ? "chart__portfolios chart__portfolios--dark"
                : "chart__portfolios"
            }
          >
            {props.portfolios.map((p) => (
              <span
                key={p}
                onClick={() => props.changePortfolio(p)}
                className={props.portfolio === p ? "active" : ""}
              >
                {p}
              </span>
            ))}
              <AddPortfolioButton setPortfolios={props.setPortfolios} />
          </div>
        )}

        <div
          className={
            auth.theme === "dark"
              ? "chart__period chart__period--dark"
              : "chart__period"
          }
        >
          {periods.map((p) => (
            <span
              key={p.name}
              onClick={() => changePeriod(p)}
              className={activePeriod === p.name ? "active" : ""}
            >
              {p.name}
            </span>
          ))}
        </div>
      </div>

      {props.isLoading && (
        <div className="chart__loader">
          <Loader />
        </div>
      )}

      {!props.isLoading && (
        <ResponsiveContainer width="100%" height={400}>
          <AreaChart width={500} height={400} data={data}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#7346D2" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#7346D2" stopOpacity={0} />
              </linearGradient>
            </defs>

            <CartesianGrid
              strokeDasharray="2 2"
              stroke={auth.theme === "dark" ? "#2B2F35" : "#F4F4F4"}
            />
            <XAxis
              dataKey="time_stamp"
              fontSize={12}
              minTickGap={10}
              stroke={auth.theme === "dark" ? "#605F61" : "#ada8b9"}
            />
            <YAxis
              domain={[minPrice, maxPrice]}
              fontSize={12}
              minTickGap={10}
              stroke={auth.theme === "dark" ? "#605F61" : "#ada8b9"}
            />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="price"
              strokeWidth={2}
              stroke="#7346D2"
              fill="url(#colorUv)"
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}
