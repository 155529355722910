import { useState, useEffect, useContext } from "react";
import ContentWrapper from "../UIElements/ContentWrapper";
import NavVerticalMenu from "./NavVerticalMenu";
import NavLinks from "./NavLinks";
import NavProfile from "./NavProfile";
import { AuthContext } from "../../context/auth-context";

import NavLogo from "./NavLogo";
import "./NavBar.scss";

export default function NavBar({ type }) {
  const auth = useContext(AuthContext);
  const [width, setWidth] = useState(window.innerWidth);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
      setOpen(false);
    });
  }, []);

  return (
    <nav className={auth.theme === "dark" ? "nav nav--dark" : "nav"}>
      <ContentWrapper>
        <div className="nav__wrapper">
          <NavLogo />
          {type !== "logo-only" && (
            <>
              {width > 1024 && (
                <>
                  <NavLinks />
                  <NavProfile />
                </>
              )}

              {width <= 1024 && (
                <div
                  className={open ? "menu open" : "menu"}
                  onClick={() => setOpen((o) => !o)}
                >
                  <div
                    className={
                      auth.theme === "dark"
                        ? "menu-burger menu-burger--dark"
                        : "menu-burger"
                    }
                  ></div>
                </div>
              )}

              {width <= 1024 && open && <NavVerticalMenu />}
            </>
          )}
        </div>
      </ContentWrapper>
    </nav>
  );
}
