import { useContext, useState, useEffect } from "react";
import Close from "../../../assets/close.svg";
import CloseLight from "../../../assets/close-light.svg";
import { AuthContext } from "../../context/auth-context";
import { useHttpClient } from "../../hooks/http-hook";
import "./AddPortfolio.scss";

const AddPortfolio = (props) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError, setIsLoading } =
    useHttpClient();

  const [portfolioName, setPortfolioName] = useState();
  const [portfolios, setPortfolios] = useState([]);
  const submitHandler = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/users/create-portfolio`,
        "POST",
        JSON.stringify({
          portfolioName,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      props.close(portfolioName);
    } catch (error) {}
  };

  useEffect(() => {
    const fetchPortfolios = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api/users/get-portfolios`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
          }
        );

        let optionList = [];
        for (let i = 0; i < responseData.portfolios.length; i++) {
          optionList.push(
            responseData.portfolios[i],
          );
        }
        // setOptions(optionList);
      } catch (err) {}
    };

    fetchPortfolios();
  }, []);

  return (
    <div
      className={
        auth.theme === "dark"
          ? "add_portfolio add_portfolio--dark"
          : "add_portfolio"
      }
    >
      <div
        className="add_portfolio__backdrop"
        onClick={() => props.close("")}
      ></div>
      <div className="add_portfolio__modal">
        <div className="add_portfolio__header">
          <h3>Create portfolio</h3>

          <button
            className="add_portfolio__close"
            onClick={() => props.close()}
          >
            <img src={auth.theme === "dark" ? CloseLight : Close} alt="Close" />
          </button>
        </div>

        <div className="add_portfolio__body">
          <div className="add_portfolio__group">
            <label htmlFor="portfolio_name">Portfolio Name</label>
            <input
              id="portfolio_name"
              type="text"
              placeholder="My Portfolio"
              value={portfolioName}
              onInput={(e) => setPortfolioName(e.target.value)}
            />
          </div>
        </div>

        <button
          disabled={!portfolioName}
          onClick={submitHandler}
          className="add_portfolio__button"
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default AddPortfolio;
