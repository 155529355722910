import ContentWrapper from "../utils/ContentWrapper";
import { ReactComponent as LogoLight } from "../../assets/logo-light.svg";
import { ReactComponent as Twitter } from "../../assets/twitter.svg";
import { ReactComponent as Facebook } from "../../assets/facebook.svg";
import { ReactComponent as Instagram } from "../../assets/instagram.svg";
import { ReactComponent as LinkedIn } from "../../assets/linkedin.svg";

export default function Footer() {
    return (
        <footer className="footer">
            <ContentWrapper>
                <div className="footer__wrapper">
                    <div className="footer__logo">
                        <LogoLight />
                        <p>©2024 Trakfolio, Inc.<br />All rights reserved</p>
                    </div>

                    <div className="footer__body">
                        <div>
                            <h3>Trakfolio</h3>
                            <a href="">Trakfolio Web</a>
                            <a href="">Trakfolio IOS</a>
                            <a href="">Trakfolio Android</a>
                        </div>

                        <div>
                            <h3>Legal</h3>
                            <a href="">Privacy Policy</a>
                            <a href="">Terms of Service</a>
                        </div>

                        <div>
                            <h3>Contacts</h3>
                            <div className="footer__contacts">
                                <a href=""><Twitter /></a>
                                <a href=""><Facebook /></a>
                                <a href=""><Instagram /></a>
                                <a href=""><LinkedIn /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </footer>
    );
}
