import { useEffect, useRef } from "react";
import { motion, useInView } from "framer-motion";

import CTAButton from "../utils/CTAButton";
import ContentWrapper from "../utils/ContentWrapper";
import TrackYourAssetsPic from "../../assets/track-your-assets.png";

export default function TrackYourAssetsSection() {
  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: true,
    margin: "0px 0px -300px 0px",
  });

  return (
    <section className="section" id="portfolio_tracker">
      <ContentWrapper>
        <motion.div className="section__wrapper">
          <motion.div
            className="section__text"
            ref={ref}
            style={{
              opacity: isInView ? 1 : 0,
              translateY: isInView ? 0 : 100,
              transition: "1s ease",
            }}
          >
            <h2>Track All Of Your Assets...In One Place!</h2>
            <div>
              <p>
                Manage all your investments with ease – from the buzz of the
                stock market to your cozy cash savings. Everything's clear and
                simple here. Track your stocks, bonds, crypto, and even NFTs,
                all in one friendly place.
              </p>
              <p>Welcome to stress-free asset tracking!</p>
            </div>
            <CTAButton light={false} />
          </motion.div>
          <div className="section__img">
            <motion.img
              style={{
                opacity: isInView ? 1 : 0,
                translateY: isInView ? 0 : 500,
                transition: "1s ease",
              }}
              src={TrackYourAssetsPic}
              alt="Track All Of Your Assets"
            />
          </div>
        </motion.div>
      </ContentWrapper>
    </section>
  );
}
