import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import NavBar from "../../shared/components/Nav/NavBar";
import UserOutline from "../../assets/user-outline-dark.svg";
import ContentWrapper from "../../shared/components/UIElements/ContentWrapper";
import Post from "../components/Post";
import timeSince from "../../shared/utils/time-since";
import Loader from "../../shared/components/Loader";
import "./Creator.scss";

const Creator = () => {
  const auth = useContext(AuthContext);
  const creatorId = useParams().creator;
  const [max, setMax] = useState(1);
  const [step, setStep] = useState(0);
  const [user, setUser] = useState();
  const [subscribers, setSubscribers] = useState();
  const [subscribed, setSubscribed] = useState();
  const [posts, setPosts] = useState([]);
  const { isLoading, error, sendRequest, clearError, setIsLoading } =
    useHttpClient();

  const getPosts = async (reset) => {
    if (reset) {
      setStep(0);
      setPosts([]);
    }

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/posts/` + creatorId,
        "POST",
        JSON.stringify({
          step: reset ? 0 : step,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      setStep(responseData.nextPage);
      setMax(responseData.max);

      console.log(responseData);

      if (responseData) setPosts((p) => [...p, ...responseData.posts]);
    } catch (error) {}
  };

  useEffect(() => {
    const getUserData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api/users/` + creatorId,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );

        setSubscribed(
          responseData.user.subscribers
            ? responseData.user.subscribers.find(
                (s) => s.toString() === auth.userId.toString()
              )
            : false
        );
        setSubscribers(
          responseData.user.subscribers
            ? responseData.user.subscribers.length
            : 0
        );
        setUser(responseData.user);
      } catch (error) {}
    };

    getUserData();
    getPosts();
  }, []);

  const bookmark = async (postId, bookmark) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/posts/bookmark`,
        "POST",
        JSON.stringify({
          bookmark,
          postId,
        }),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        },
        false
      );

      setPosts((posts) => {
        posts.find((p) => p.id === postId).bookmarks = responseData.bookmarks;
        return posts;
      });
    } catch (err) {}
  };

  const like = async (postId, like) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/posts/like`,
        "POST",
        JSON.stringify({
          like,
          postId,
        }),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        },
        false
      );

      setPosts((posts) => {
        posts.find((p) => p.id === postId).likes = responseData.likes;
        return posts;
      });
    } catch (err) {}
  };

  const deletePost = async (postId) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/posts/`,
        "DELETE",
        JSON.stringify({
          postId,
        }),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        },
        false
      );

      getPosts(true);
    } catch (err) {}
  };

  const subscribe = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/users/subscribe`,
        "POST",
        JSON.stringify({
          subscribe: !subscribed,
          creatorId,
        }),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        },
        false
      );

      if (!subscribed) {
        setSubscribers((s) => ++s);
      } else {
        setSubscribers((s) => --s);
      }

      setSubscribed((s) => !s);
    } catch (err) {}
  };

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;

    if (
      scrollTop + clientHeight >= scrollHeight - 50 &&
      !isLoading &&
      max > step
    ) {
      setIsLoading(true);
      getPosts(false);
    }
  };

  return (
    <section
      onScroll={handleScroll}
      className={
        auth.theme === "dark"
          ? "dashboard_section dashboard_section--dark"
          : "dashboard_section"
      }
    >
      <NavBar type="full" transparent={false} />
      <ContentWrapper>
        {user && (
          <div
            className={
              auth.theme === "dark" ? "creator creator--dark" : "creator"
            }
          >
            <div className="creator__header">
              <img src={user.img ? user.img : UserOutline} alt="" />
              <h1>{user.name}</h1>
              <p>
                {subscribers} {subscribers === 1 ? "Subscriber" : "Subscribers"}
              </p>
              {creatorId !== auth.userId && (
                <button
                  className={subscribed ? "followed" : ""}
                  onClick={subscribe}
                >
                  {subscribed ? "Followed" : "Follow"}
                </button>
              )}
            </div>

            {posts && (
              <div className="creator__posts">
                {posts.map((p) => (
                  <Post
                    id={p.id}
                    creator={p.creator}
                    name={p.creator.name}
                    time={timeSince(p.date)}
                    postContent={p.text}
                    postPic={p.imgURL}
                    likes={p.likes}
                    comments={p.comments}
                    bookmarks={p.bookmarks}
                    bookmark={bookmark}
                    like={like}
                    deletePost={creatorId === auth.userId && deletePost}
                  />
                ))}
              </div>
            )}
          </div>
        )}

        {isLoading && <Loader />}
      </ContentWrapper>
    </section>
  );
};

export default Creator;
