import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/auth-context";
import Logo from "../../../assets/logo.svg";
import LogoLight from "../../../assets/logo-light.svg";

import "./NavLogo.scss";

export default function NavLogo() {
  const auth = useContext(AuthContext);

  return (
    <Link to="/" className="nav__logo">
      <img src={auth.theme === "dark" ? LogoLight : Logo} alt="Trakfolio" />
    </Link>
  );
}
