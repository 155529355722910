import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../shared/context/auth-context";
import UserOutline from "../../assets/user-outline-dark.svg";
import Close from "../../assets/close.svg";
import CloseLight from "../../assets/close-light.svg";
import { ReactComponent as LikeOutlined } from "../../assets/like.svg";
import { ReactComponent as LikeFill } from "../../assets/like-fill.svg";
import { ReactComponent as CommentIcon } from "../../assets/comment.svg";
import { ReactComponent as BookmarkOutlined } from "../../assets/bookmark-outlined.svg";
import { ReactComponent as BookmarkFill } from "../../assets/bookmark-fill.svg";
import { useHttpClient } from "../../shared/hooks/http-hook";
import "./PostComments.scss";
import Loader from "../../shared/components/Loader";
import Comment from "./Comment";

const PostComments = (props) => {
  const auth = useContext(AuthContext);
  const [text, setText] = useState("");
  const [comments, setComments] = useState([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const createComment = async (e) => {
    e.preventDefault();

    try {
      await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/comments/post`,
        "POST",
        JSON.stringify({
          text,
          id: props.id,
          creator: auth.userId,
        }),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        },
        false
      );

      setText("");
      props.setCommentsCount((c) => ++c);
      getComments();
    } catch (err) {}
  };

  const deleteComment = async (commentId) => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/comments/post`,
        "DELETE",
        JSON.stringify({
          commentId,
          id: props.id,
        }),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        },
        false
      );

      props.setCommentsCount((c) => --c);
      getComments();
    } catch (err) {}
  };

  const getComments = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/comments/post/${props.id}`,
        "GET",
        null,
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        }
      );

      setComments(responseData.comments);
    } catch (err) {}
  };

  const like = async (commentId, like) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/comments/like`,
        "POST",
        JSON.stringify({
          like,
          commentId,
          userId: auth.userId,
        }),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        },
        false
      );

      setComments((comments) => {
        comments.find((p) => p.id === commentId).likes = responseData.likes;
        return comments;
      });
    } catch (err) {}
  };

  useEffect(() => {
    getComments();
  }, []);

  return (
    <div
      className={
        auth.theme === "dark"
          ? "post_comments post_comments--dark"
          : "post_comments"
      }
    >
      <div className="post_comments__backdrop" onClick={props.closePost}></div>
      <div className="post_comments__modal">
        <button onClick={props.closePost}>
          <img src={auth.theme === "dark" ? CloseLight : Close} alt="Close" />
        </button>

        <div className={auth.theme === "dark" ? "post post--dark" : "post"}>
          <img
            className="post__profile_pic"
            src={props.creator.img ? props.creator.img : UserOutline}
            alt={props.name}
          />

          <div className="post__body">
            <div className="post__header">
              <div className="post__name_wrapper">
                <span className="post__name">{props.name}</span>
                <span className="post__tag">{props.time}</span>
              </div>

              {props.creator._id !== auth.userId && (
                <button
                  className={
                    props.followed ? "post__button followed" : "post__button"
                  }
                >
                  {props.followed ? "Followed" : "Follow"}
                </button>
              )}
            </div>
            <p className="post__par">{props.postContent}</p>
            {props.postPic && (
              <img className="post__img" src={props.postPic} alt="" />
            )}

            <div className="post__footer">
              <div className="post__footer_item" onClick={props.setLike}>
                {props.liked ? <LikeFill /> : <LikeOutlined />}
                {props.likesCount > 0 ? props.likesCount : ""}
              </div>

              <div className="post__footer_item">
                <CommentIcon />
                {props.commentsCount > 0 ? props.commentsCount : ""}
              </div>

              <div className="post__footer_item" onClick={props.setBookmark}>
                {props.bookmarked ? <BookmarkFill /> : <BookmarkOutlined />}
                {props.bookmarksCount > 0 ? props.bookmarksCount : ""}
              </div>
            </div>
          </div>
        </div>

        <h3>Comments</h3>

        <div
          class={auth.theme === "dark" ? "new_post new_post--dark" : "new_post"}
        >
          <img src={auth.profileImg ? auth.profileImg : UserOutline} alt="" />

          <form class="new_post__body" onSubmit={createComment}>
            <textarea
              placeholder="Share your thoughts..."
              onInput={(e) => setText(e.target.value)}
              value={text}
            ></textarea>
            <div class="new_post__footer">
              <button class="community__button">Post</button>
            </div>
          </form>
        </div>

        <div className="comments">
          {comments &&
            comments
              .filter((c) => c.creator.id === auth.userId)
              .map((c) => (
                <Comment
                  key={c.id}
                  deleteHandler={() => {
                    deleteComment(c.id);
                  }}
                  {...c}
                  like={like}
                />
              ))}

          {comments &&
            comments
              .filter((c) => c.creator.id !== auth.userId)
              .map((c) => (
                <Comment
                  key={c.id}
                  deleteHandler={() => {
                    deleteComment(c.id);
                  }}
                  {...c}
                  like={like}
                />
              ))}

          {isLoading && <Loader />}
        </div>
      </div>
    </div>
  );
};

export default PostComments;
