import SettingsIcon from "../../assets/settings.svg";
import UserIcon from "../../assets/user-outline-settings.svg";
import { AuthContext } from "../../shared/context/auth-context";
import Documents from "../../assets/documents.svg";
// import { FaSignOutAlt } from "react-icons/fa";
import { useContext } from "react";

const SettingsNav = (props) => {
  const auth = useContext(AuthContext);
  const { activeTab, changeActiveTab } = props;
  const signoutHandler = () => {
    auth.logout();
  }
  return (
    <div
      className={
        auth.theme === "dark"
          ? "settings__nav settings__nav--dark"
          : "settings__nav"
      }
    >
      <button
        onClick={() => changeActiveTab("app")}
        className={activeTab === "app" ? "active" : ""}
      >
        <img src={SettingsIcon} alt="App Settings" />
        App Settings
      </button>
      <button
        onClick={() => changeActiveTab("account")}
        className={activeTab === "account" ? "active" : ""}
      >
        <img src={UserIcon} alt="Account Settings" />
        Account Settings
      </button>
      <button
        onClick={() => changeActiveTab("documents")}
        className={activeTab === "documents" ? "active" : ""}
      >
        <img src={Documents} alt="Documents" />
        Documents
      </button>
      <button
        onClick={signoutHandler}
        className={activeTab === "signout" ? "active" : ""}
      >
        {/* <img src={Documents} alt="SignOut" /> */}
        {/* <FaSignOutAlt /> */}
        Sign Out
      </button>
    </div>
  );
};

export default SettingsNav;
