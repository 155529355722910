import { useContext, useState, useEffect } from "react";
import Close from "../../../assets/close.svg";
import CloseLight from "../../../assets/close-light.svg";
import { AuthContext } from "../../context/auth-context";
import Select from "../FormElements/Select";
import { useHttpClient } from "../../hooks/http-hook";
import "./AddTransaction.scss";
import SelectPortfolio from "./SelectPortfolio";
import SearchAsset from "./SearchAsset";
import CreatableSelect from "react-select/creatable";
import SearchFungibleAsset from "./SearchFungibleAsset";

const createOption = (label) => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ""),
});

const AddTransaction = (props) => {
  const auth = useContext(AuthContext);
  const [activeTab, setActiveTab] = useState("stock");
  const [isInputLoading, setIsInputLoading] = useState(false);
  const { isLoading, error, sendRequest, clearError, setIsLoading } =
    useHttpClient();

  const [options, setOptions] = useState([]);
  const [portfolio, setPortfolio] = useState();
  const [type, setType] = useState("buy");
  const [asset, setAsset] = useState(props.ticker);
  const [quantity, setQuantity] = useState();
  const [date, setDate] = useState();
  const [price, setPrice] = useState();

  const handleActiveTab = (tab) => {
    setActiveTab(tab);
  };

  const handleCreate = (inputValue) => {
    setIsInputLoading(true);
    setTimeout(() => {
      const newOption = createOption(inputValue);
      setIsInputLoading(false);
      setOptions((prev) => [...prev, newOption]);
      setPortfolio(newOption);
    }, 1000);
  };

  const submitHandler = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/users/update-portfolio`,
        "POST",
        JSON.stringify({
          portfolio: portfolio.label,
          transaction_type: type,
          asset_type: activeTab,
          asset,
          quantity,
          transaction_date: new Date(date).toISOString(),
          price,
          currency: "usd",
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      props.close();
    } catch (error) {}
  };

  useEffect(() => {
    const fetchPortfolios = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api/users/get-portfolios`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
          }
        );
        if (responseData.portfolios.length <= 1) {
          setOptions([]);
        } else {
          let optionList = [];
          for (let i = 1; i < responseData.portfolios.length; i++) {
            optionList.push({
              label: responseData.portfolios[i],
              value: responseData.portfolios[i],
            });
          }
          setOptions(optionList);
        }
      } catch (err) {}
    };

    fetchPortfolios();
  }, []);
  return (
    <div
      className={
        auth.theme === "dark"
          ? "add_transaction add_transaction--dark"
          : "add_transaction"
      }
    >
      <div className="add_transaction__backdrop" onClick={props.close}></div>
      <div className="add_transaction__modal">
        <div className="add_transaction__header">
          <h3>Add transaction</h3>

          <button className="add_transaction__close" onClick={props.close}>
            <img src={auth.theme === "dark" ? CloseLight : Close} alt="Close" />
          </button>
        </div>
        <div className="add_transaction__tab">
          <button
            className={activeTab === "stock" && "active"}
            onClick={() => handleActiveTab("stock")}
          >
            Stock
          </button>
          <button
            className={activeTab === "crypto" && "active"}
            onClick={() => handleActiveTab("crypto")}
          >
            Crypto
          </button>
        </div>
        <hr className="portfolio__split" />

        <div className="add_transaction__body">
          <CreatableSelect
            isClearable
            isDisabled={isInputLoading}
            isLoading={isInputLoading}
            onChange={(newValue) => setPortfolio(newValue)}
            onCreateOption={handleCreate}
            options={options}
            value={portfolio}
          />

          <div className="add_transaction__group">
            <label>Transaction Type</label>
            <Select
              data={[
                { name: "Buy", value: "buy" },
                { name: "Sell", value: "sell" },
              ]}
              value="buy"
              changeValue={(val) => {
                setType(val);
              }}
            />
          </div>
          {activeTab === "stock" ? (
            <SearchAsset search={props.ticker} setAsset={setAsset} />
          ) : (
            <SearchFungibleAsset search={props.ticker} setAsset={setAsset} />
          )}
          <div className="add_transaction__group">
            <label htmlFor="quantity">Quantity</label>
            <input
              id="quantity"
              type="text"
              placeholder="5"
              value={quantity}
              onInput={(e) => setQuantity(e.target.value)}
            />
          </div>

          <div className="add_transaction__group">
            <label htmlFor="date">Transaction Date</label>
            <input
              id="date"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>

          <div className="add_transaction__group">
            <label htmlFor="price">Price (USD)</label>
            <input
              id="price"
              type="price"
              placeholder="100"
              value={price}
              onInput={(e) => setPrice(e.target.value)}
            />
          </div>
        </div>

        <div className="add_transaction__total">
          <p>Total Amount</p>
          <h2>{price && quantity ? price * quantity : "0.00"} USD</h2>
        </div>

        <button
          disabled={
            !portfolio || !type || !asset || !quantity || !date || !price
          }
          onClick={submitHandler}
          className="add_transaction__button"
        >
          Add
        </button>
      </div>
    </div>
  );
};

export default AddTransaction;
