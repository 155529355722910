export default function timeSince(dateIsoString) {
  const seconds = Math.floor((new Date() - new Date(dateIsoString)) / 1000);

  let interval = seconds / 31536000; // Seconds in 365 days
  if (interval > 1) {
    return Math.floor(interval) + "y ago";
  }
  interval = seconds / 2592000; // Seconds in 30 days
  if (interval > 1) {
    return Math.floor(interval) + "month ago";
  }
  interval = seconds / 604800; // Seconds in 7 days
  if (interval > 1) {
    return Math.floor(interval) + "w ago";
  }
  interval = seconds / 86400; // Seconds in 24 hours
  if (interval > 1) {
    return Math.floor(interval) + "d ago";
  }
  interval = seconds / 3600; // Seconds in an hour
  if (interval > 1) {
    return Math.floor(interval) + "h ago";
  }
  interval = seconds / 60; // Seconds in a minute
  if (interval > 1) {
    return Math.floor(interval) + "min ago";
  }
  return seconds === 0 ? "1s ago" : Math.floor(seconds) + "s ago";
}
