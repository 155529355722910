import { useContext, useEffect, useState } from "react";
import { useHttpClient } from "../../hooks/http-hook";
import { AuthContext } from "../../context/auth-context";
import ArrowUpRight from "../../../assets/arrow-up-right.svg";
import ArrowUpRightLight from "../../../assets/arrow-up-right-light.svg";
import "./AssetsSearchContainer.scss";
import { useNavigate } from "react-router";
import ErrorModal from "../UIElements/ErrorModal";
import Loader from "../Loader";

const AssetsSearchContainer = (props) => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const [assets, setAssets] = useState([]);

  const { isLoading, error, sendRequest, clearError, setIsLoading } =
    useHttpClient(true);

  const clickHandler = (asset) => {
    setAssets([]);
    props.clearSearch();
    navigate("/assets/" + asset);
  };

  useEffect(() => {
    const fetchAssetsData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api/assets/search`,
          "POST",
          JSON.stringify({
            query: props.query,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
        setAssets(responseData.results);
      } catch (err) {}
    };

    fetchAssetsData();
  }, [props.query, sendRequest, auth.tiingoKey]);

  return (
    <div
      className={
        auth.theme === "dark"
          ? "search_results search_results--dark"
          : "search_results"
      }
    >
      {error && <ErrorModal error={error} clearError={clearError} />}
      {isLoading && <Loader />}
      {!isLoading && (
        <div className="search_results__body">
          {assets.map((a) => (
            <div
              className="search_results__item"
              onClick={() => clickHandler(a.symbol)}
            >
              <div className="search_results__item_header">
                <h3>{a.symbol}</h3>
                <img
                  src={auth.theme === "dark" ? ArrowUpRightLight : ArrowUpRight}
                  alt=""
                />
              </div>
              <p>{a.shortname}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AssetsSearchContainer;
