import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/auth-context";
import SearchIcon from "../../../assets/search.svg";
import SearchIconLight from "../../../assets/search-light.svg";
import Clear from "../../../assets/clear.svg";
import "./SearchAsset.scss";
import SearchAssetContainer from "./SearchAssetContainer";

const SearchAsset = (props) => {
  const auth = useContext(AuthContext);
  const [open, setOpen] = useState();
  const [search, setSearch] = useState(props.search);

  const inputHandler = (e) => {
    if (e.target.value) {
      setOpen(true);
      setSearch(e.target.value);
    } else {
      setOpen(false);
      setSearch("");
    }
  };

  const clickHandler = (e) => {
    setOpen(false);
    setSearch("");
  };

  useEffect(() => {
    props.setAsset(search);
  }, [search]);

  return (
    <div className="add_transaction__group">
      <label htmlFor="asset">Asset</label>
      <div className="search_asset">
        <img
          src={auth.theme === "dark" ? SearchIconLight : SearchIcon}
          alt="Search"
        />
        <input
          id="asset"
          type="text"
          placeholder="AAPL"
          value={search}
          onInput={inputHandler}
        />
        {search && <img src={Clear} alt="Clear" onClick={clickHandler} />}
      </div>

      {open && (
        <SearchAssetContainer
          query={search}
          clickHandler={(ticker) => {
            setOpen(false);
            setSearch(ticker);
          }}
        />
      )}
    </div>
  );
};

export default SearchAsset;
