import { useContext, useState, useEffect } from "react";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import DashboardContainer from "../../shared/components/UIElements/DashboardContainer";
import Post from "./Post";
import Loader from "../../shared/components/Loader";
import timeSince from "../../shared/utils/time-since";

const Posts = (props) => {
  const auth = useContext(AuthContext);
  return (
    <DashboardContainer>
      <div
        className={
          auth.theme === "dark"
            ? "community__forum_header community__forum_header--dark"
            : "community__forum_header"
        }
      >
        <button
          className={props.activeTab === "Recent" ? "active" : ""}
          onClick={() => props.changeTab("Recent")}
        >
          Recent
        </button>
        <button
          className={props.activeTab === "Following" ? "active" : ""}
          onClick={() => props.changeTab("Following")}
        >
          Following
        </button>
        <button
          className={props.activeTab === "Bookmarks" ? "active" : ""}
          onClick={() => props.changeTab("Bookmarks")}
        >
          Bookmarks
        </button>
      </div>
      <hr />
      {props.isLoading && <Loader />}
      {!props.isLoading && props.posts && props.posts.length > 0 && (
        <div className="community__forum_container">
          {props.posts.map((p, index) => (
            <Post
              key={index}
              id={p.id}
              creator={p.creator}
              name={p.creator ? p.creator.name : ''}
              time={timeSince(p.date)}
              postContent={p.text}
              postPic={p.imgURL}
              likes={p.likes}
              comments={p.comments}
              bookmarks={p.bookmarks}
              bookmark={props.bookmark}
              like={props.like}
            />
          ))}
        </div>
      )}

      {!props.isLoading && props.posts.length === 0 && (
        <p className="community__no_subscriptions">No posts</p>
      )}
    </DashboardContainer>
  );
};

export default Posts;
