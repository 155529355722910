import { useContext } from "react";
import { AuthContext } from "../context/auth-context";
import roundNumber from "../utils/round-number";
import "./HorizontalAssetItem.scss";

export default function HorizonalAssetItem(props) {
  const auth = useContext(AuthContext);
  const { logo, name, ticker, price, prevPrice } = props;

  const diffPrice = price - prevPrice;
  const diffPercentage = ((diffPrice / prevPrice) * 100).toFixed(2);

  return (
    <div
      onClick={props.onClick}
      className={
        auth.theme === "dark"
          ? "horizontal_asset horizontal_asset--dark"
          : "horizontal_asset"
      }
    >
      <div className="horizontal_asset__name">
        {logo && (
          <div className="horizontal_asset__img">
            <img src={logo} alt={name} />
          </div>
        )}

        <div>
          <p>{name}</p>
          <p>{ticker}</p>
        </div>
      </div>

      <div className="horizontal_asset__price">
        <p>{roundNumber(price)} USD</p>
        <span className={diffPercentage >= 0 ? "positive" : "negative"}>
          {diffPercentage}%
        </span>
      </div>
    </div>
  );
}
