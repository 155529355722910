export default function roundNumber(num) {
  let number = +num;
  let cleanDigits;

  if (Math.abs(number) < 1) {
    const scientificNotation = number.toExponential();
    const [digits, exp] = scientificNotation.split("e");
    cleanDigits = number.toFixed(Math.abs(exp) + 2);
  } else {
    cleanDigits = number.toFixed(2);
  }

  return cleanDigits;
}
