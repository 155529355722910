import { useContext } from "react";
import DashboardContainer from "../../shared/components/UIElements/DashboardContainer";
import { AuthContext } from "../../shared/context/auth-context";
import roundNumber from "../../shared/utils/round-number";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

function calculateTotals(transactions) {
  let totalInvestedCapital = 0;
  let priceGain = 0;
  let totalRevenue = 0;

  transactions.forEach((transaction) => {
    const { transaction_price, transaction_quantity, real_time_price } =
      transaction;

    // Calculate total invested capital
    totalInvestedCapital += transaction_price * transaction_quantity;

    // Calculate price gain (or loss)
    priceGain += (real_time_price - transaction_price) * transaction_quantity;

    // Calculate total revenue
    totalRevenue += real_time_price * transaction_quantity;
  });

  return {
    totalInvestedCapital,
    priceGain,
    totalRevenue,
  };
}

function calculateYearlyPriceGain(transactions) {
  const yearlyGains = {};

  transactions.forEach((transaction) => {
    const year = new Date(transaction.transaction_date).getFullYear();
    const priceGain =
      (transaction.real_time_price - transaction.transaction_price) *
      transaction.transaction_quantity;

    // Initialize the year in the result object if it doesn't exist
    if (!yearlyGains[year]) {
      yearlyGains[year] = 0;
    }

    // Aggregate the price gain for the year
    yearlyGains[year] += priceGain;
  });

  // Transform the results into an array of objects with year and value properties
  const resultArray = Object.keys(yearlyGains).map((year) => ({
    year: parseInt(year),
    value: yearlyGains[year],
  }));

  return resultArray;
}

const Performance = (props) => {
  const auth = useContext(AuthContext);
  const data = props.assets.map((a) => {
    return {
      transaction_date: a.transaction_data.transaction_date,
      transaction_price: roundNumber(a.transaction_data.price),
      transaction_quantity: a.transaction_data.quantity,
      real_time_price: roundNumber(a.asset_data.price),
    };
  });

  const totals = calculateTotals(data);
  const yearlyPriceGain = calculateYearlyPriceGain(data);

  return (
    <DashboardContainer>
      <h2 className="big">Performance</h2>

      <div
        className={
          auth.theme === "dark"
            ? "portfolio_chart portfolio_chart--dark"
            : "portfolio_chart"
        }
      >
        <ResponsiveContainer width="100%" height={170}>
          <BarChart
            width="100%"
            height="100%"
            data={yearlyPriceGain}
            maxBarSize={50}
          >
            <Bar dataKey="value" fill="#7346D2" />
            <Tooltip />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div
        className={
          auth.theme === "dark"
            ? "portfolio__financials portfolio__financials--dark"
            : "portfolio__financials"
        }
      >
        <div>
          <span>Invested Capital</span>
          <span>{roundNumber(totals.totalInvestedCapital)}</span>
        </div>

        <div>
          <span>Price Gain</span>
          <span className={totals.priceGain > 0 ? "positive" : "negative"}>
            {roundNumber(totals.priceGain)}
          </span>
        </div>

        <div>
          <span>Total Return</span>
          <span>{roundNumber(totals.totalRevenue)}</span>
        </div>
      </div>
    </DashboardContainer>
  );
};

export default Performance;
