import { useState, useEffect, useContext } from "react";
import { themes } from "../../shared/data/themes";
import { currencies } from "../../shared/data/currencies";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import Button from "../../shared/components/FormElements/Button";
import Select from "../../shared/components/FormElements/Select";
import Loader from "../../shared/components/Loader";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";

const AppSettings = () => {
  const auth = useContext(AuthContext);
  const [theme, setTheme] = useState("light");
  const [currency, setCurrency] = useState("USD");
  const [firstLoad, setFirstLoad] = useState(true);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_API_URL}/api/users/` + auth.userId,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
          }
        );

        setTheme(responseData.user.theme);
        setCurrency(responseData.user.currency);
        setFirstLoad(false);
      } catch (err) {}
    };

    fetchUserData();
  }, []);

  const updateSettings = async (e) => {
    e.preventDefault();

    try {
      await sendRequest(
        `${process.env.REACT_APP_API_URL}/api/users/update-user`,
        "POST",
        JSON.stringify({
          theme,
          currency,
        }),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        }
      );

      auth.updateTheme(theme);
    } catch (err) {}
  };

  return (
    <div
      className={
        auth.theme === "dark"
          ? "settings__col_2 settings__col_2--dark"
          : "settings__col_2"
      }
    >
      <h2>App Settings</h2>
      {!firstLoad && (
        <form className="settings__form" onSubmit={updateSettings}>
          <div className="settings__inputs">
            <div
              className={
                auth.theme === "dark"
                  ? "settings__group settings__group--dark"
                  : "settings__group"
              }
            >
              <label>Theme</label>
              <Select
                data={themes}
                value={theme}
                changeValue={(val) => setTheme(val)}
              />
            </div>

            <div
              className={
                auth.theme === "dark"
                  ? "settings__group settings__group--dark"
                  : "settings__group"
              }
            >
              <label>Currency</label>
              <Select
                data={currencies}
                value={currency}
                changeValue={(val) => setCurrency(val)}
              />
            </div>
          </div>

          <Button text="Update" />
        </form>
      )}
      {isLoading && <Loader />}
      {error && <ErrorModal error={error} clearError={clearError} />}
    </div>
  );
};

export default AppSettings;
