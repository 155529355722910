import { ReactComponent as ArrowRight } from "../../assets/arrow-right.svg";

export default function CTAButton({ light }) {
  return (
    <button className={light ? "cta_button" : "cta_button cta_button--dark"}>
      <div>
        <ArrowRight />
      </div>
      <span>Get Started For Free</span>
    </button>
  );
}
