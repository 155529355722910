import { useEffect, useRef } from "react";
import { motion, useInView } from "framer-motion";

import CTAButton from "../utils/CTAButton";
import ContentWrapper from "../utils/ContentWrapper";
import GetDeepInsightPic from "../../assets/get-deep-insights-into-your-portfolio.png";

export default function GainDeepInsightSection() {
  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: true,
    margin: "0px 0px -300px 0px",
  });

  return (
    <section className="section" id="deep_insight">
      <ContentWrapper>
        <div className="section__wrapper">
          <motion.div
            className="section__text"
            ref={ref}
            style={{
              opacity: isInView ? 1 : 0,
              translateY: isInView ? 0 : 100,
              transition: "1s ease",
            }}
          >
            <h2>Get Deep Insights Into Your Portfolio</h2>
            <div>
              <p>
                See your investments in a whole new light. Trakfolio breaks down
                your portfolio by industry and asset class, making it simple to
                understand. No more guessing games – just clear charts.
              </p>
              <p>
                Adjust your strategy with confidence, using smart,
                easy-to-digest data. It's your financial world, made crystal
                clear and totally manageable.
              </p>
            </div>
            <CTAButton light={false} />
          </motion.div>
          <div className="section__img">
            <motion.img
              style={{
                opacity: isInView ? 1 : 0,
                translateY: isInView ? 0 : 500,
                transition: "1s ease",
              }}
              src={GetDeepInsightPic}
              alt="Get Deep Insights Into Your Portfolio"
            />
          </div>
        </div>
      </ContentWrapper>
    </section>
  );
}
