import { useRef, useEffect } from "react";
import { motion, useInView } from "framer-motion";

import ContentWrapper from "../utils/ContentWrapper";
import Metamask from "../../assets/metamask.png";
import InteractiveBrokers from "../../assets/interactive-brokers.png";
import Robinhood from "../../assets/robinhood.png";
import Coinbase from "../../assets/coinbase.png";
import Fidelity from "../../assets/fidelity.png";
import Binance from "../../assets/binance.png";

export default function ConnectPortfolioSection() {
  const ref = useRef(null);
  const isInView = useInView(ref, {
    once: true,
    margin: "0px 0px -300px 0px",
  });

  return (
    <section className="section section--light_blue">
      <ContentWrapper>
        <div className="portfolios">
          <h2 className="portfolios__header">
            Connect Your Portfolio Directly
          </h2>

          <div ref={ref} className="portfolios__grid">
            <motion.div
              animate={isInView && { opacity: 1, translateY: 0 }}
              initial={{ opacity: 0, translateY: 50 }}
              transition={{ duration: 0.3 }}
              className="portfolios__item"
            >
              <img src={Metamask} alt="Metamask" />
              <p>Metamask</p>
            </motion.div>
            <motion.div
              animate={isInView && { opacity: 1, translateY: 0 }}
              initial={{ opacity: 0, translateY: 50 }}
              transition={{ duration: 0.3, delay: 0.1 }}
              className="portfolios__item"
            >
              <img src={InteractiveBrokers} alt="Interactive Brokers" />
              <p>Interactive Brokers</p>
            </motion.div>
            <motion.div
              animate={isInView && { opacity: 1, translateY: 0 }}
              initial={{ opacity: 0, translateY: 50 }}
              transition={{ duration: 0.3, delay: 0.2 }}
              className="portfolios__item"
            >
              <img src={Robinhood} alt="Robinhood" />
              <p>Robinhood</p>
            </motion.div>
            <motion.div
              animate={isInView && { opacity: 1, translateY: 0 }}
              initial={{ opacity: 0, translateY: 50 }}
              transition={{ duration: 0.3, delay: 0.3 }}
              className="portfolios__item"
            >
              <img src={Coinbase} alt="Coinbase" />
              <p>Coinbase</p>
            </motion.div>
            <motion.div
              animate={isInView && { opacity: 1, translateY: 0 }}
              initial={{ opacity: 0, translateY: 50 }}
              transition={{ duration: 0.3, delay: 0.4 }}
              className="portfolios__item"
            >
              <img src={Fidelity} alt="Fidelity" />
              <p>Fidelity</p>
            </motion.div>
            <motion.div
              animate={isInView && { opacity: 1, translateY: 0 }}
              initial={{ opacity: 0, translateY: 50 }}
              transition={{ duration: 0.3, delay: 0.5 }}
              className="portfolios__item"
            >
              <img src={Binance} alt="Binance" />
              <p>Binance</p>
            </motion.div>
          </div>
        </div>
      </ContentWrapper>
    </section>
  );
}
