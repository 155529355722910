import { useContext } from "react";
import { AuthContext } from "../../context/auth-context";
import "./Button.scss";

const Button = (props) => {
  const auth = useContext(AuthContext);

  return (
    <button
      className={
        auth.theme === "dark" ? "form_button form_button--dark" : "form_button"
      }
    >
      {props.text}
    </button>
  );
};

export default Button;
