import { Link, NavLink } from "react-router-dom";

import NavBar from "../../shared/components/Nav/NavBar";
import ContentWrapper from "../../shared/components/UIElements/ContentWrapper";
import GoogleLogin from "../components/GoogleLogin";

import "./Auth.scss";
import AuthForm from "../components/AuthForm";

export default function Auth() {
  const path = window.location.pathname;
  const isLogin = path === "/login";

  return (
    <main className="auth">
      <NavBar type="logo-only" />
      <ContentWrapper>
        <div className="auth__wrapper">
          <header className="auth__header">
            <NavLink to="/login">Log In</NavLink>
            <NavLink to="/signup">Sign up</NavLink>
          </header>

          <AuthForm isLogin={isLogin} />

          <div className="auth__separator">
            <hr />
            <span>OR</span>
            <hr />
          </div>

          <GoogleLogin />

          {!isLogin && (
            <p className="auth__par">
              By proceeding, you agree to Trakfolio’s <Link>Terms of Use</Link>{" "}
              & <Link>Privacy Policy</Link>
            </p>
          )}
        </div>
      </ContentWrapper>
    </main>
  );
}
